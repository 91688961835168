import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import auctionService from "../../../services/auction/auction.service";
import { errorToast } from "../../../react-toastfiy/toast";
import Select from "react-select";

const CreateFinancierModalPopup = (props) => {
  const { modal, data, setdata, setModal } = props;
  console.log({ data });
  const [brandList, setBrandList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [errors, setErrors] = useState({});
  console.log({ brandList, stateList });
  //   GetUserStateList;

  useEffect(() => {
    try {
      auctionService.GetUserStateList().then((res) => {
        // setLoading(false);
        console.log({ res });
        let state_list = res.states.map((val, key) => ({
          label: val,
          value: val,
        }));

        setStateList(state_list);
      });
      auctionService.GetBrandList().then((res) => {
        // setLoading(false);
        console.log({ res });
        let brand_list = res.brands.map((val, key) => ({
          label: val,
          value: val,
        }));

        setBrandList(brand_list);
      });
    } catch (error) {
      errorToast(error.msg);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const errors = {};
      if (!data?.name) errors.name = "Name is required.";
      if (!data?.state) errors.state = "State is required.";
      if (!data?.fShorthand) errors.fShorthand = "fShorthand is required.";
      if (!data?.brand) errors.brand = "Brand is required.";

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }

      const form_data = new FormData();
      form_data.append("name", data?.name);
      form_data.append("state", data?.state);
      form_data.append("fShorthand", data?.fShorthand);
      form_data.append("brand", data?.brand);

      auctionService.CreateFinancier(form_data).then((res) => {
        if (res.status === 200) {
          console.log(res.msg, { res });
          setModal(false);
        } else {
          console.log({ res });
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    try {
      const errors = {};
      if (!data?.name) errors.name = "Name is required.";
      if (!data?.state) errors.state = "State is required.";
      if (!data?.fShorthand) errors.fShorthand = "fShorthand is required.";
      if (!data?.brand) errors.brand = "Brand is required.";

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }

      const form_data = new FormData();
      form_data.append("name", data?.name);
      form_data.append("state", data?.state);
      form_data.append("fShorthand", data?.fShorthand);
      form_data.append("brand", data?.brand);
      form_data.append("financier_id", data?.financier_id);

      auctionService.UpdateFinancier(form_data).then((res) => {
        if (res.status === 200) {
          console.log(res.msg, { res });
          setModal(false);
        } else {
          console.log({ res });
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        {data?.financier_id ? (
          <h5>Update Financier</h5>
        ) : (
          <h5>Create Financier</h5>
        )}
      </Modal.Header>
      <Modal.Body>
        <div className="container table-responsive mt-2">
          <div className="col-12">
            <label>Name</label>
            <input
              className="form-control"
              type="text"
              name="name"
              value={data?.name}
              onChange={(e) => {
                setdata((prev) => ({ ...prev, ["name"]: e.target.value }));
              }}
              required
            />
            {errors.name && (
              <small className="text-danger">{errors.name}</small>
            )}
          </div>
          <div className="col-12">
            <label>State</label>
            <Select
              options={stateList}
              name="state"
              onChange={(e) => {
                setdata((prev) => ({
                  ...prev,
                  ["state"]: e.value,
                }));
              }}
              value={stateList.find((state) => state?.value === data?.state)}
              required
            />
            {errors.state && (
              <small className="text-danger">{errors.state}</small>
            )}
          </div>
          <div className="col-12">
            <label>fShorthand</label>
            <input
              className="form-control"
              type="text"
              name="fShorthand"
              value={data?.fShorthand}
              onChange={(e) => {
                setdata((prev) => ({
                  ...prev,
                  ["fShorthand"]: e.target.value,
                }));
              }}
              required
            />
            {errors.fShorthand && (
              <small className="text-danger">{errors.fShorthand}</small>
            )}
          </div>
          <div className="col-12">
            <label>Brand</label>

            <Select
              options={brandList}
              onChange={(e) => {
                setdata((prev) => ({
                  ...prev,
                  ["brand"]: e.value,
                }));
              }}
              value={brandList.find((brand) => brand?.value === data?.brand)}
              required
            />
            {errors.brand && (
              <small className="text-danger">{errors.brand}</small>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>
          Close
        </Button>
        {data?.financier_id !== "" ? (
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        ) : (
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CreateFinancierModalPopup;
