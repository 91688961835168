import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";
import AddFile from "../../img/undraw_add_files_re_v09g.svg";
import jwtDecode from "jwt-decode";

const UploadMultipleFile = () => {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);
  useEffect(() => {
    document.title = "Upload Multiple Assets";
  }, []);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 370);
  const [isScreen, setIsScreen] = useState(window.innerWidth < 770);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 370);
    setIsScreen(window.innerWidth < 770);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const asset_id = searchParams.get("uid");
  console.log({ asset_id });

  const [data, setData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = (e, type) => {
    e.preventDefault();
    try {
      setButtonLoading(true);
      const form_data = new FormData();
      form_data.append("assets", data.file);
      form_data.append("uid", asset_id);
      auctionService.UploadMultipleAsset(form_data).then((res) => {
        setButtonLoading(false);
        if (res?.status === 200) {
          successToast("Asset Upload Successfully");
          history.push(`/meratractor/auctions/view/assets?uid=${asset_id}`);
          const formData = new FormData();
          formData.append("uid", asset_id);
          auctionService.RemoveCardAPI(formData).then((response) => {
            console.log({ response });
            if (response) {
              history.push(`/meratractor/auctions/view/assets?uid=${asset_id}`);
            }
          });
        } else {
          errorToast(res.msg);
        }
      });
    } catch (error) {
      errorToast(error);
      console.log(error.msg);
    }
  };

  const handleDownload = () => {
    const fileUrl = `${process.env.PUBLIC_URL}/sample.xlsx`;

    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", "Sample_Excel.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-3 mb-2">
          <span className="d-flex justify-content-between col-1">
            <i
              className="bi bi-arrow-left-circle "
              onClick={() =>
                history.push(
                  `/meratractor/auctions/add_assets?uid=${asset_id}`
                )
              }
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </span>

          <div className="col-10 mt-2 float-start ms-2">
            <h4 className="">Upload Excel</h4>
          </div>
        </div>
      </div>

      <div className="container d-flex d-flex-wrap justify-content-between ">
        <div
          className={`col-md-7 col-12 p-3 mb-2 rounded ${
            !isScreen ? " bg-body" : ""
          }`}
          // style={{
          //   marginTop: "2rem",
          // }}
        >
          {/* <hr className="soften mt-2" /> */}
          <form className="ms-auto" onSubmit={handleSubmit}>
            <div className="row mt-2">
              <div className="col-md-9 mb-3 form-group">
                <input
                  className="form-control"
                  id="file"
                  type="file"
                  name="file"
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["file"]: e.target.files[0],
                    }))
                  }
                  required
                />
              </div>

              <div className="col-md-3 form-group">
                <div className="d-flex justify-content-center">
                  {buttonLoading ? (
                    <button
                      className="btn btn-primary"
                      type="button"
                      disabled
                      id="loadingBtnTp"
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <button className="btn btn-primary" type="submit">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-9 mb-3 form-group">
              <span>Download </span>:
              <a
                className="ms-2"
                onClick={handleDownload}
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "none",
                }}
              >
                Sample Excel
              </a>
            </div>
          </form>
        </div>
        <div className="ms-2">
          <img src={AddFile} alt="Fill Form Img" width="100%" height="80%" />
        </div>
      </div>
    </>
  );
};

export default UploadMultipleFile;
