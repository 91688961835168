import axios from "axios";
import jwtDecode from "jwt-decode";
import { errorToast } from "../../react-toastfiy/toast";

const excludedURLs = ["/refreshToken", "/login-react"];

// const MT_URL = "https://meratractor.com";
// const MT_URL = "http://192.168.0.130:8001";
// const MT_URL = "http://127.0.0.0:8001";
// const MT_URL = "http://0.0.0.0:8001";
// const MT_URL = "http://192.168.1.8:8003";
// const MT_URL = "http://localhost:8001";
// const MT_URL = "http://192.168.72.179:8001";
// const MT_URL = "http://172.20.10.14:8001";
// const MT_URL = "http://192.168.1.9:8001";
// const MT_URL = "http://192.168.1.17:8001";
// const MT_URL = "http://192.168.1.6:8003";
// const MT_URL = "https://test.meratractor.com";
const MT_URL = "https://api.meratractor.com";
// const MT_URL = "http://localhost:8001";

// const location = useLocation();
// const asset_id = location.pathname.split(":")[1];

const cookieAxios = axios.create({
  baseURL: MT_URL,
  withCredentials: true,
});

const handle401Response = () => {
  localStorage.clear();
  window.tokenAboutToExpire = false;
  window.fetchingRefreshToken = false;
  window.requestQueue = [];
  window.location.replace("/sign-in");
};

// let token = config?.headers?.Authorization
//   ? config?.headers?.Authorization
//   : config?.headers?.common?.Authorization;

// if (token) {
//   const decoded_access_token_exp_time = jwtDecode(token).exp;
//   const current_time_second = Math.round(new Date() / 1000);
//   if (decoded_access_token_exp_time <= current_time_second) {
//     token = undefined;
//   }
// }

//   const refreshToken = localStorage.getItem("refreshToken");

//   if (
//     refreshToken &&
//     !token &&
//     !window.fetchingRefreshToken &&
//     (!excludedURLs.includes(config.url) || config.url.startsWith(MT_URL))
//   ) {
//     window.tokenAboutToExpire = true;
//     window.fetchingRefreshToken = true;
//     try {
//       const response = await axios.post(
//         "/refreshToken",
//         {},
//         {
//           headers: {
//             Authorization: refreshToken,
//           },
//         }
//       );

//       if (response.data.status === 200) {
//         const newAccessToken = response.data.token;
//         axios.defaults.headers.common["Authorization"] = newAccessToken;
//         window.tokenAboutToExpire = false;
//         window.fetchingRefreshToken = false;

//         window.requestQueue.forEach(({ resolve, config }) => {
//           resolve({
//             ...config,
//             headers: { ...config?.headers, Authorization: newAccessToken },
//           });
//         });
//         window.requestQueue = [];

//         return {
//           ...config,
//           headers: { ...config?.headers, Authorization: newAccessToken },
//         };
//       } else if (response.data.status === 401) {
//         handle401Response();
//       } else {
//         errorToast(response.data.msg);
//       }
//     } catch (error) {
//       console.error("Error refreshing token:", error);
//       handle401Response();
//     }
//   }

//   if (
//     !window.tokenAboutToExpire ||
//     excludedURLs.includes(config.url) ||
//     config.url.startsWith(MT_URL)
//   ) {
//     return config;
//   } else {
//     return new Promise((resolve) => {
//       window.requestQueue.push({ resolve, config });
//     });
//   }
// });

// Response interceptor to handle 401 errors and other responses

cookieAxios.interceptors.response.use(
  (resp) => {
    console.log({ resp });
    if (
      resp?.data?.status === 401 &&
      !excludedURLs
        ?.map?.((val) => `${MT_URL}${val}`)
        ?.includes(resp.config?.url)
    ) {
      errorToast(resp?.data?.msg);
      handle401Response();
      return resp;
    } else {
      return resp;
    }
  },
  (error) => {
    if (
      error.response?.status === 401 &&
      excludedURLs
        ?.map?.((val) => `${MT_URL}${val}`)
        ?.includes(error.config?.url)
    ) {
      errorToast(error.response?.data?.msg);
      handle401Response();
    }
    return Promise.reject(error);
  }
);

// cookieAxios.interceptors.request.use((config) => {
//   console.log({ url: config.url });
//   if (config?.url === `${MT_URL}/login-react`) {
//     return config;
//   }

//   return {
//     ...config,
//     headers: { ...config?.headers, "X-Custom-Header": "erp" },
//   };
// });

class AuctionService {
  MeratractorLogin = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/login-react`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  AuctionLogout = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/logout-app-user`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAllApprovalPending = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-auction-approval-pending`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllAssetApprovalPending = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-asset-approval-pending`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ClosedApprovalRequest = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/update-auction-erp-auction-close-request`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  UpdateApprovalStatus = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/update-auction-erp-asset-status`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllPaymentPending = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-auctions-payment-pending`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllAssetPaymentPending = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-assets-payment-pending`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllCompletedAuction = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-completed-auctions-from-date-range`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  EditChangeClosedRequest = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/edit-change-close-request`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionChangeClosedRequestApprovalPending = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-completed-auctions-change-close-request-st`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ClosedRequestApproval = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/update-change-close-request`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetFinancierWiseAuctionList = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/auction-erp-st-dashboard`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetStateWiseFinancierAuctionList = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/auction-erp-admin-dashboard`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  ApprovedPendingCount = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-auction-approval-pending-count`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  PaymentPendingCount = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-auctions-payment-pending-count`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  AuctionChangeClosedRequestApprovalPendingCount = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-completed-auctions-change-close-request-st-count`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetBackoutApprovalCount = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "get",
      url: `${MT_URL}/auctions/get-backout-charges-no-data-count`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetState = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-user-state-permissions`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetSegmentType = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "get",
      url: `${MT_URL}/auctions/get-all-segments`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllAuctionReport = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-representative-reports-auctions-listing`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionRepresentativeReportsAssetsListing = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-representative-reports-assets-listing`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllAuctionState = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-all-states-open-api`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetUserList = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/wallet/get_phone_name`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  UpdateAuctionSummaryAsset = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/update-auction-summary-asset`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllAssetForBackoutApprovalPending = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-backout-charges-no-data`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  UpdateBackoutChargesStatus = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/update-backout-charges-status`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionsListBondKYCDownload = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/auction-list-bond-kyc-download`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAssetListBondKYCDownload = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/asset-list-bond-kyc-download`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionWiseReleaseOrBankNOCPending = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/auction-wise-release-or-banknoc-pending`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAssetWiseReleaseOrBankNOCPending = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/asset-wise-release-or-banknoc-pending`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ReleaseDoneOfAsset = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/release-done-of-asset`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  BankNOCDoneOfAsset = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/banknoc-done-of-asset`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  AuctionWonReports = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/auction-won-report`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ClientCompletedAuctionIDsForERP = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/client/get-completed-auction-ids-for-erp`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ClientCompletedAuctionDataForERP = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/client/get-completed-auction-data-for-erp`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionERPAuctionsPaymentPendingCount = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-auctions-payment-pending-count`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetBrandList = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-brand-list`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetFinanciersList = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/api/financiers_erp`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  CreatAuction = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/add`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAuction = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/get_auctions`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetARAuction = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-admin`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  SwitchAuctionData = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/switch/auction`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAssetByAssetId = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/get_auction_details_by_uid`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UpdateAssetByAssetId = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/update_auction_by_id`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UploadSingleAsset = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/upload_single_asset`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UploadMultipleAsset = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/upload_multiple_assets`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  RemoveCardAPI = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/delete/card_one_by_one`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionStatus = async (asset_id, formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/asset-status?uid=${asset_id}`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetViewAuctionAssets = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/view/assets_admin`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetViewAuctionAssetsFirebase = async () => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get/firebase`,
      // data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAuctionSummary = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/auction_summary`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAuctionAcr = async (asset_id, formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/acr?uid=${asset_id}`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  LockBid = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/lock-bid`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UnLockBid = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/unlock-bids`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAssetPictures = async (uid, formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get_asset_pictures_by_id?ref_no=${uid}`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UploadAssetPictures = async (uid, formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/upload_asset_images?uid=${uid}`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  DeleteAssetPictures = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/delete/assetImage`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  SetAssetPicturesPrimary = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/view/asset_images`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetBidderNameAndPhone = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/wallet/get_phone_name`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UpdateAuctionSummary = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/update-auction-summary-asset`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UploadImage = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/upload_asset_excel`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetUserStateList = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/user/api/statelist`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetUserLoginDetails = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/user/get/loginDetails`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UserLogout = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/user/logout/users`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetFinancier = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/get-all-financiers`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  CreateFinancier = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/add-financier`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  UpdateFinancier = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/update-financier`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  DeleteFinancier = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/delete-financier-by-id`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetSegments = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/get-all-segments-page-wise`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  CreateSegment = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/add-segment`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UpdateSegment = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/update-segment`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  DeleteSegment = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/delete-segment-by-id`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetStateByPageNo = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/get-all-states-pg-no`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  CreateState = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/add-state`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  UpdateState = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/update-state`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  DeleteState = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/delete-state-by-id`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetBrand = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-all-brands`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  CreateBrand = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/add-brand`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  UpdateBrand = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/update-brand`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  DeleteBrand = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/delete-brand-by-id`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  CreateSingleTransaction = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/wallet/single-transaction`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetTransactionByUser = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/wallet/view-transactions-by-user`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetMultiTransactionForOneUser = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/wallet/upload-transactions-for-one-user`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAllTransaction = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/wallet/get-all-transactions`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAllTransactionbyDate = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/wallet/get-all-transactions-by-date`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UploadMultipleTransactionExcelFile = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/wallet/upload-multiple-transactions`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetLedgerBalanceDetails = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/wallet/ledger-balance-details`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetUserListData = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/user/api/userlist`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetStateListData = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/user/api/statelist`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  Permissions = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get/state/permission`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UpdatePermissions = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/state/permission`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  WalletNotLinked = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/wallet/wallet-not-linked-userlist`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  WalletLinked = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/wallet/link-wallet`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  AddUserToWallet = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/user/add-user-to-wallet`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  ExportExcelUserDetails = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "get",
      url: `${MT_URL}/wallet/export-excel-user-details`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAssetImage = async (newAsset, formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "GET",
      url: `${MT_URL}/auctions/get_asset_pictures_by_id?ref_no=${newAsset}`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  EditAssetImagePrimary = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/view/asset_images`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  EditAssetImageDelete = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/delete/assetImage`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAssetSummary = async (newAsset, formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/asset_summary?ref_no=${newAsset}`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  DeleteBid = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/delete/bid`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  DeleteAsset = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/delete/asset`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAssetDetails = async (url, formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "get",
      url: `${MT_URL}/auctions/view/assetbyid?ref_no=${url}`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UpdateAssetDetails = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/edit/asset`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAutoBid = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/view/view_auction_autobid`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  DeleteAutoBid = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/delete_user_autobid`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UploadMultipleTransactionForSingleUserHandleExcelofUserDetails = async (
    formData
  ) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/wallet/excel-user-details-data`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  DownloadAllBalanceList = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/wallet/ledger-balance-details-download-data`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionCompletionDetails = async (formData, source = {}) => {
    return axios({
      method: "post",
      url: `/operations/get-auction-completion-state-financier-wise`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionInDateRangeList = async (formData) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/dashboard/get-auction-in-date-range`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAuctionUserList = async (formData) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/dashboard/auction-user-data`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAssetWiseBiddingData = async (formData) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/dashboard/asset-wise-bidding-data`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetMMFSLLotsCount = async (formData) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/dashboard/get-mmfsl-lots-count`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetMMFSLLotsCountModalData = async (formData) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/dashboard/get-lot-wise-assets`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetMMFSLLotsCountModal = async (formData) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/dashboard/get-lot-wise-unique-states`,
      data: formData,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  RetryACRGeneration = async (formData) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/retry-acr-generation`,
      data: formData,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetUserAnalysisData = async (formData, source = {}) => {
    return cookieAxios({
      method: "get",
      url: `${MT_URL}/dashboard/get-this-month-active-dormant-churn-users`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  DownloadViewAllTransaction = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/wallet/get-all-transactions-download`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  searchFinancier = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auction/search-financier-by-name`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  searchSegment = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auction/search-segment-by-name`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  SearchState = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auction/search-state-by-name`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  SearchBrand = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auction/search-brand-by-name`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  SearchPermission = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/user/api/userlist_search`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
}
export default new AuctionService();
