import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Link, useLocation, useHistory } from "react-router-dom";
import SearchBox from "../../common/SearchBox";
import auctionService from "../../services/auction/auction.service";
import Swal from "sweetalert2";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import { indianCurrencyFormater } from "../../helpers/functions/currencyFormater";
import jwtDecode from "jwt-decode";

const AssetSummary = () => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  const history = useHistory();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 486);
  console.log({ isSmallScreen });

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 486);
  };

  useEffect(() => {
    document.title = "Asset Summary";
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const singleuploadbreadcrumb = {
    height: isSmallScreen ? "60px" : "30px",
    borderRadius: "0",
  };

  const singleuploadbreadcrumbMargin = {
    marginTop: isSmallScreen ? "-14px" : "-12px",
    marginBottom: isSmallScreen ? "14px" : "",
  };

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const asset_id = searchParams.get("ref_no");
  const uid = searchParams.get("uid");
  const url = `${asset_id}&uid=${uid}`;

  console.log({ url, uid, asset_id });

  const headers = [
    { header: "Reference No", dataKey: "Reference No" },
    { header: "Make & Model", dataKey: "Make Model" },
    { header: "Bidder Name", dataKey: "Bidder Name" },
    { header: "Bidder Phone", dataKey: "Bidder Phone" },
    { header: "Bidder Pan", dataKey: "Bidder Pan" },
    { header: "Bid", dataKey: "max_bid" },
    { header: "Date", dataKey: "date" },
  ];

  const [auctionData, setAuctionData] = useState([]);
  const [filterAuctionData, setFilterAuctionData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      setLoading(true);
      const form_data = new FormData();
      form_data.append("uid", uid);
      form_data.append("ref_no", asset_id);
      auctionService.GetAssetSummary(asset_id, form_data).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          const data = res?.data;
          const newData = data.map((item) => ({
            ...item,
            bidder_phone: item["Bidder Phone"],
          }));
          data.map((val) => val);
          console.log({ data });
          setAuctionData(newData);
          setFilterAuctionData(newData);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleDelete = (e, data, index) => {
    e.preventDefault();
    console.log({ e, data, index }, data?.id, data?.live_auction_id);
    try {
      const form_data = new FormData();
      form_data.append("asset_id", data?.id);
      form_data.append("live_auction_id", data?.live_auction_id);
      Swal.fire({
        title: "Delete Bid?",
        html: `<p>Do you really want to delete this bid !</p>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes,delete it!",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            const response = await auctionService.DeleteBid(form_data);
            if (response.status === 200) {
              successToast(response?.msg);
              // window.location.replace();
              window.location.reload();
            } else {
              errorToast(response?.msg);
            }
          } catch (error) {
            console.log(error);
            errorToast(error?.message);
          }
        },
      });
    } catch (error) {
      console.log(error);
      errorToast(error);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;

    const searchData = auctionData.filter((val) => {
      return String(val?.bidder_phone)
        .toLowerCase()
        .includes(value.toLowerCase());
    });

    if (value) {
      setFilterAuctionData(searchData);
    } else {
      setFilterAuctionData(auctionData);
    }
  };
  console.log({ filterAuctionData });

  return (
    <>
      <div className="container-fluid shadow-sm mb-2">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container ">
        <div className="row mt-2 mb-2">
          <div className=" col-12 col-md-2">
            <i
              className="bi bi-arrow-left-circle"
              onClick={() =>
                history.push(
                  `/meratractor/auctions/modify/asset?ref_no=${asset_id}&uid=${uid}`
                )
              }
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </div>
          <div className="col-12 col-md-4">
            <h5 className="mt-2">{asset_id}</h5>
          </div>
          <div className="col-md-6 col-12 mt-1">
            <input
              className="form-control"
              type="text"
              name="name"
              placeholder="Search by Bidder Phone..."
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>

      {loading ? (
        <div
          className="d-flex justify-content-center container"
          style={{ minHeight: "80vh", alignItems: "center" }}
        >
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>
          <div className="container table-responsive">
            <div
              className="table-responsive text-center"
              style={{ minHeight: "5vh", maxHeight: "80vh" }}
            >
              <table className=" table table-hover table-bordered">
                <thead className="thead-dark">
                  <tr
                    className="text-center"
                    style={{ fontSize: "small", position: "sticky", top: 0 }}
                  >
                    <th> Sr. No</th>
                    {headers.map((column) => (
                      <th className="col" key={column.header}>
                        {column.header}
                      </th>
                    ))}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(filterAuctionData) &&
                  Object.entries(filterAuctionData).length === 0 ? (
                    <div className=" text-danger text-center mt-2 "></div>
                  ) : (
                    Object.entries(filterAuctionData).map(
                      (rowData, rowIndex) => (
                        <tr
                          key={rowIndex}
                          style={{
                            fontSize: "small",
                          }}
                        >
                          <td>{rowIndex + 1}</td>
                          {headers.map((header, index) => (
                            <>
                              {header.dataKey === "max_bid" ? (
                                <td key={index}>
                                  <b>
                                    {indianCurrencyFormater(
                                      rowData[1][header.dataKey]
                                    )}
                                  </b>
                                </td>
                              ) : (
                                <td key={index}>
                                  {rowData[1][header.dataKey]}
                                </td>
                              )}
                            </>
                          ))}
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={(e) => {
                                handleDelete(e, rowData[1], rowIndex);
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </table>
              {filterAuctionData && filterAuctionData.length === 0 && (
                <div className="text-danger text-center mt-2 ">
                  No auction took place for {asset_id}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AssetSummary;
