import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";
import AddAsset from "../../img/undraw_automobile_repair_ywci.svg";
import { round } from "lodash";
import jwtDecode from "jwt-decode";

const SingleUpload = () => {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);

  useEffect(() => {
    document.title = "Add Single Asset";
  }, []);
  const history = useHistory();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 370);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 370);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const singleuploadbreadcrumb = {
    height: isSmallScreen ? "60px" : "30px",
    borderRadius: "0",
  };

  const singleuploadbreadcrumbMargin = {
    marginTop: isSmallScreen ? "-14px" : "-12px",
    marginBottom: isSmallScreen ? "14px" : "",
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const auctionId = searchParams.get("uid");
  const [data, setData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSubmit = (e, type) => {
    e.preventDefault();
    try {
      setButtonLoading(true);
      const errors = {};
      if (!data.reference_no) errors.reference_no = "Reference No. is required";
      if (!data.registration_number)
        errors.registration_number = "Registration Number is required";
      if (!data.make_model) errors.make_model = "Make & Model is required";
      if (!data.engine_no) errors.engine_no = "Engine No. is required";
      if (!data.chassis_no) errors.chassis_no = "Chassis No. is required";
      if (
        !data.manufacturing_year ||
        isNaN(data.manufacturing_year) ||
        data.manufacturing_year <= 0
      )
        errors.manufacturing_year = "Valid Manufacturing Year is required";
      if (!data.yard_name) errors.yard_name = "Yard Name is required";
      if (!data.rc_status) errors.rc_status = "RC Status is required";
      if (!data.insurance_status)
        errors.insurance_status = "Insurance Status is required";
      if (!data.tax_status) errors.tax_status = "Tax Status is required";
      if (!data.note) errors.note = "Note is required";
      // if (!data.fuel_type) errors.fuel_type = "Fuel Type is required";
      if (!data.yard_address) errors.yard_address = "Yard Address is required";
      if (!data.start_price || isNaN(data.start_price) || data.start_price <= 0)
        errors.start_price = "Valid Start Price is required";
      if (!data.recommended_bid)
        errors.recommended_bid = "Recommended Bid is required";
      // if (!data.old_customer_name)
      //   errors.old_customer_name = "Old Customer Name is required";
      if (!data.file || data.file.length === 0)
        errors.file = "File is required";

      if (Object.keys(errors).length > 0) {
        console.log(errors);
        setErrors(errors);
        return;
      }

      const form_data = new FormData();
      form_data.append("ref_no", data.reference_no);
      form_data.append("reg_no", data.registration_number);
      form_data.append("make_model", data.make_model);
      form_data.append("eng_no", data.engine_no);
      form_data.append("chassis_no", data.chassis_no);
      form_data.append("mfg_yr", data.manufacturing_year);
      form_data.append("yard_name", data.yard_name);
      form_data.append("rc_status", data.rc_status);
      form_data.append("insurance_status", data.insurance_status);
      form_data.append("tax_status", data.tax_status);
      form_data.append("note", data.note);
      form_data.append("fuel_type", data.fuel_type);
      form_data.append("yard_address", data.yard_address);
      form_data.append("start_price", data.start_price);
      form_data.append("recommended_bid", data.recommended_bid);
      form_data.append("old_customer_name", data.old_customer_name);
      form_data.append("uid", auctionId);
      auctionService.UploadSingleAsset(form_data).then((res) => {
        setButtonLoading(false);
        if (res?.status === 200) {
          successToast("Asset Create Successfully");
          history.push(`/meratractor/auctions/view/assets?uid=${auctionId}`);
        } else {
          errorToast(res.msg);
        }
      });
    } catch (error) {
      errorToast(error);
      console.log(error.msg);
    }
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-3 mb-2">
          <span className="d-flex justify-content-between col-1">
            <i
              className="bi bi-arrow-left-circle "
              onClick={() =>
                history.push(
                  `/meratractor/auctions/add_assets?uid=${auctionId}`
                )
              }
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </span>

          <div className="col-10 mt-2 float-start ms-2">
            <h4 className="">Add Single Asset</h4>
          </div>
        </div>
      </div>
      <div className="container d-flex d-flex-wrap justify-content-between ">
        <div className="col-md-7  col-12  bg-body p-3 mb-2  rounded">
          <form className="ms-auto" onSubmit={handleSubmit}>
            <div className="row mt-2">
              <div className="col-md-6 mb-3 form-group">
                <label for="segment">Reference No.</label>

                <input
                  className="form-control"
                  placeholder="Reference No."
                  id="reference_no"
                  type="text"
                  name="reference_no"
                  value={data.reference_no}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["reference_no"]: e.target.value,
                    }))
                  }
                  required
                />
              </div>

              <div className="col-md-6 form-group">
                <label for="financier">Registration Number</label>

                <input
                  className="form-control"
                  placeholder="Registration Number"
                  id="registration_number"
                  type="text"
                  name="registration_number"
                  required
                  value={data.registration_number}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["registration_number"]: e.target.value,
                    }))
                  }
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-6 mb-3 form-group">
                <label for="state">Make & Model</label>

                <input
                  className="form-control"
                  placeholder="Make & Model"
                  id="make_model"
                  type="text"
                  name="make_model"
                  value={data.make_model}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["make_model"]: e.target.value,
                    }))
                  }
                  required
                />
              </div>

              <div className="col-md-6 form-group">
                <label for="description">Engine No.</label>

                <input
                  className="form-control"
                  placeholder="Engine No."
                  id="engine_no."
                  type="text"
                  name="engine_no."
                  value={data.engine_no}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["engine_no"]: e.target.value,
                    }))
                  }
                  required
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-6 mb-3 form-group">
                <label for="auction_type">Chassis No.</label>

                <input
                  className="form-control"
                  placeholder="Chassis No."
                  id="chassis_no."
                  type="text"
                  name="chassis_no."
                  value={data.chassis_no}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["chassis_no"]: e.target.value,
                    }))
                  }
                  required
                />
              </div>
              <div className="col-md-6 form-group">
                <label for="description"> Manufacturing Year </label>

                <input
                  className="form-control"
                  placeholder="Manufacturing Year"
                  id="manufacturing_year."
                  type="number"
                  name="manufacturing_year."
                  value={data.manufacturing_year}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["manufacturing_year"]: e.target.value,
                    }))
                  }
                  required
                  min={new Date().getFullYear() - 30}
                  max={new Date().getFullYear()}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-6 mb-3 form-group">
                <label for="description">Yard Name</label>
                <input
                  className="form-control"
                  placeholder="Yard Name"
                  id="yard_name."
                  type="text"
                  name="yard_name."
                  value={data.yard_name}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["yard_name"]: e.target.value,
                    }))
                  }
                  required
                />
              </div>
              <div className="col-md-6 form-group">
                <label for="description">RC Status</label>
                <input
                  className="form-control"
                  placeholder="RC Status"
                  id="rc_status."
                  type="text"
                  name="rc_status."
                  value={data.rc_status}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["rc_status"]: e.target.value,
                    }))
                  }
                  required
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-6 mb-3 form-group">
                <label for="description">Insurance Status</label>
                <input
                  className="form-control"
                  placeholder="Insurance Status"
                  id="insurance_status."
                  type="text"
                  name="insurance_status."
                  value={data.insurance_status}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["insurance_status"]: e.target.value,
                    }))
                  }
                  required
                />
              </div>
              <div className="col-md-6 form-group">
                <label for="description">Tax Status</label>
                <input
                  className="form-control"
                  placeholder="Tax Status"
                  id="tax_status"
                  type="text"
                  name="tax_status"
                  value={data.tax_status}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["tax_status"]: e.target.value,
                    }))
                  }
                  required
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-6 mb-3 form-group">
                <label for="description">Note</label>
                <input
                  className="form-control"
                  placeholder="Note"
                  id="note"
                  type="text"
                  name="note"
                  value={data.note}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["note"]: e.target.value,
                    }))
                  }
                  required
                />
              </div>
              <div className="col-md-6 form-group">
                <label for="description">Fuel Type</label>
                <input
                  className="form-control"
                  placeholder="Fuel Type"
                  id="fuel_type."
                  type="text"
                  name="fuel_type."
                  value={data.fuel_type}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["fuel_type"]: e.target.value,
                    }))
                  }
                  // required
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-6 mb-3 form-group">
                <label for="description">Yard Address</label>

                <input
                  className="form-control"
                  placeholder="Yard Address"
                  id="yard_address"
                  type="text"
                  name="yard_address"
                  value={data.yard_address}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["yard_address"]: e.target.value,
                    }))
                  }
                  required
                />
              </div>
              <div className="col-md-6 form-group">
                <label for="description">Start Price</label>
                <input
                  className="form-control"
                  placeholder="Start Price"
                  id="start_price"
                  type="number"
                  name="start_price"
                  value={data.start_price}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["start_price"]: e.target.value,
                    }))
                  }
                  required
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-6 mb-3 form-group">
                <label for="description">Recommended Bid</label>
                <input
                  className="form-control"
                  placeholder="Recommended Bid"
                  id="recommended_bid"
                  type="text"
                  name="recommended_bid"
                  value={data.recommended_bid}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["recommended_bid"]: e.target.value,
                    }))
                  }
                  required
                />
              </div>

              <div className="col-md-6 form-group">
                <label for="description">Old Customer Name</label>
                <input
                  className="form-control"
                  placeholder="Old Customer Name"
                  id="old_customer_name"
                  type="text"
                  name="old_customer_name"
                  value={data.old_customer_name}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["old_customer_name"]: e.target.value,
                    }))
                  }
                  // required
                />
              </div>
            </div>
            <label for="description">Upload Image</label>
            <input
              className="form-control"
              id="file"
              type="file"
              name="file"
              multiple
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  file: Array.from(e.target.files),
                }))
              }
              required
            />
            <div className="d-flex  justify-content-center  mt-4">
              {buttonLoading ? (
                <button
                  className="btn btn-primary col-4"
                  type="button"
                  disabled
                  id="loadingBtnTp"
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="btn btn-primary  col-4"
                  type="submit"
                  // onClick={(e) => handleSubmit(e, "edit")}
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
        <div className="ms-2">
          <img src={AddAsset} alt="Fill Form Img" width="100%" height="80%" />
        </div>
      </div>
    </>
  );
};

export default SingleUpload;
