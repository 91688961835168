import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import auctionService from "../../../services/auction/auction.service";
import { errorToast, successToast } from "../../../react-toastfiy/toast";
import Select from "react-select";

const CreateBrandModalPopup = (props) => {
  const { modal, data, setdata, setModal, initialValue } = props;
  console.log({ data, modal });
  const [brandList, setBrandList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const errors = {};
      if (!data?.name) errors.name = "State Name is required.";
      if (!data?.logo_url) errors.logo_url = "Logo Url is required.";
      if (!data?.shorthand) errors.shorthand = "Shorthand is required.";

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }

      const form_data = new FormData();
      form_data.append("name", data?.name);
      form_data.append("logo_url", data?.logo_url);
      form_data.append("shorthand", data?.shorthand);

      auctionService.CreateBrand(form_data).then((res) => {
        if (res.status === 200) {
          console.log(res.msg, { res });
          successToast(res?.msg);

          setModal(false);
        } else {
          errorToast(res?.msg);
          console.log({ res });
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    try {
      const errors = {};
      if (!data?.name) errors.name = "State Name is required.";
      if (!data?.logo_url) errors.logo_url = "Logo Url is required.";
      if (!data?.shorthand) errors.shorthand = "Shorthand is required.";

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }

      const form_data = new FormData();
      form_data.append("name", data?.name);
      form_data.append("logo_url", data?.logo_url);
      form_data.append("brand_id", data?.brand_id);
      form_data.append("shorthand", data?.shorthand);

      auctionService.UpdateBrand(form_data).then((res) => {
        if (res.status === 200) {
          console.log(res.msg, { res });
          setModal(false);
          setdata(initialValue);
        } else {
          console.log({ res });
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        {data?.brand_id ? <h5>Update brand</h5> : <h5>Create brand</h5>}
      </Modal.Header>
      <Modal.Body>
        <div className="container table-responsive mt-2">
          <div className="col-12">
            <label>Name</label>
            <input
              className="form-control"
              type="text"
              name="name"
              value={data?.name}
              onChange={(e) => {
                setdata((prev) => ({ ...prev, ["name"]: e.target.value }));
              }}
              required
            />
            {errors.name && (
              <small className="text-danger">{errors.name}</small>
            )}
          </div>

          <div className="col-12">
            <label>Shorthand</label>
            <input
              type="text"
              id="shorthand"
              name="shorthand"
              className="form-control"
              value={data?.shorthand}
              onChange={(e) => {
                setdata((prev) => ({
                  ...prev,
                  ["shorthand"]: e.target.value,
                }));
              }}
              accept="image/*"
            />
            {errors.shorthand && (
              <small className="text-danger">{errors.shorthand}</small>
            )}
          </div>
          <div className="col-12">
            <label>Logo Url</label>
            <input
              type="text"
              id={"logo_url"}
              name="logo_url"
              className="form-control"
              //   hidden
              onChange={(e) => {
                setdata((prev) => ({
                  ...prev,
                  ["logo_url"]: e.target.value,
                }));
              }}
              accept="image/*"
            />
            {errors.logo_url && (
              <small className="text-danger">{errors.logo_url}</small>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>
          Close
        </Button>
        {data?.brand_id !== "" ? (
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        ) : (
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CreateBrandModalPopup;
