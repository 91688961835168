import React, { useState, useEffect } from "react";
import auctionService from "../../../../services/auction/auction.service";
import { errorToast } from "../../../../react-toastfiy/toast";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const UploadMultipleTransaction = () => {
  const history = useHistory();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 430);
  const [isSmall, setIsSmall] = useState(window.innerWidth < 770);
  const [isSmallScreenMargin, setIsSmallScreenMargin] = useState(
    window.innerWidth < 430
  );

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 430);
    setIsSmallScreenMargin(window.innerWidth < 430);
    setIsSmall(window.innerWidth < 770);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  const phone = user.phone;
  let initialValue = {
    transaction_file: "",
  };

  const [data, setData] = useState(initialValue);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const form_data = new FormData();
    form_data.append("transaction", data?.transaction_file);
    form_data.append("phone", phone);
    auctionService.UploadMultipleTransactionExcelFile(form_data).then((res) => {
      setButtonLoading(false);
      if (res.status === 200) {
        Swal.fire({
          title: "Update Successfully",
          icon: "success",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            try {
              window.location.href = "all-transactions";
            } catch (error) {
              console.log(error);
              errorToast(error?.message);
            }
          },
        });
      } else {
        errorToast(res.msg);
      }
    });
  };

  const downloadExcel = () => {
    const data = [
      ["phone", ""],
      ["transaction type", ""],
      ["description", ""],
      ["amount", ""],
    ];
    const transposedData = data[0].map((_, colIndex) =>
      data.map((row) => row[colIndex])
    );

    const ws = XLSX.utils.aoa_to_sheet(transposedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "example.xlsx");
  };

  const handleExcelofUserDetails = (e) => {
    e.preventDefault();

    auctionService
      .UploadMultipleTransactionForSingleUserHandleExcelofUserDetails()
      .then((res) => {
        if (res && res.length > 0) {
          const data = [
            ["phone", "name"],
            ...res.map((user) => [user.phone, user.name]),
          ];
          const ws = XLSX.utils.aoa_to_sheet(data);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          XLSX.writeFile(wb, "user_details.xlsx");
        } else {
          console.log("No data to export");
        }
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-3 mb-2">
          <span className="d-flex justify-content-between col-1">
            <i
              className="bi bi-arrow-left-circle "
              onClick={() => history.push("/meratractor/wallet/multi-user-txn")}
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </span>

          <div className="col-10 mt-2 float-start ms-2">
            <h4 className="">Transaction</h4>
          </div>
        </div>
      </div>

      <div className="container d-flex d-flex-wrap justify-content-between ">
        <div
          className={
            isSmallScreen
              ? "col-md-8 col-lg-6 col-12  bg-body p-3 mb-2"
              : "col-md-8 col-lg-6 col-12 bg-body p-3 mb-2  rounded"
          }
          style={{
            marginTop: "2rem",
          }}
        >
          <hr className="soften mt-2" />
          <form className="ms-auto" onSubmit={handleSubmit}>
            <div className="row mt-3 align-items-center">
              <label>Please Upload Excel File</label>
              <div className="col-md-12 form-group">
                <input
                  className="form-control"
                  type="file"
                  name="transaction_file"
                  id="inputGroupFile02"
                  onChange={(e) => {
                    setData({ ["transaction_file"]: e.target.files[0] });
                  }}
                  required
                />
              </div>
            </div>

            <div className="d-flex  justify-content-center  mt-4">
              {buttonLoading ? (
                <button
                  className="btn btn-primary mt-2"
                  type="button"
                  disabled
                  id="loadingBtnTp"
                >
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button className="btn btn-primary  col-4" type="submit">
                  Submit
                </button>
              )}
            </div>

            <div class="row mt-2">
              <div class="col-12 ">
                Download :
                <Link className="ms-1 me-1" onClick={downloadExcel}>
                  Sample Excel{" "}
                </Link>
                |
                <Link className="ms-1 me-1" onClick={handleExcelofUserDetails}>
                  Excel of User Details
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UploadMultipleTransaction;
