import React, { useEffect } from "react";
import UserWallet from "../../../img/add_user_wallet.png";
import LogoutUser from "../../../img/logout_users.png";

import CardBlock from "../../../common/CardBlock";
import ErrorHandler from "../../../common/ErrorHandler";

function StateHome(props) {
  useEffect(() => {
    document.title = "Home";
  }, []);

  try {
    return (
      <>
        <div className="container container-body">
          <CardBlock
            name="Manage State"
            logo={UserWallet}
            to="/meratractor/manage-states"
          />
          {/* <CardBlock
            name="Admin section"
            logo={LogoutUser}
            to="/meratractor/user/logout/users"
          /> */}
        </div>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
}

export default StateHome;
