import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { errorToast, successToast } from "../../../react-toastfiy/toast";
import auctionService from "../../../services/auction/auction.service";
import CreateBrandModalPopup from "./CreateBrandModalPopup";

const ManageBrand = () => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [financiersData, setFinanciersData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [arrayLength, setArrayLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [modal, setModal] = useState(false);
  let initialValue = {
    name: "",
    brand_id: "",
    logo_url: "",
  };
  const [modalData, setModalData] = useState(initialValue);

  useEffect(() => {
    try {
      // if (modal === false) {
      setLoading(true);
      const form_data = new FormData();
      form_data.append("pg_no", currentPage);
      auctionService.GetBrand(form_data).then((res) => {
        setLoading(false);
        if (res?.length >= 0) {
          const brand_list = res?.map((val, index) => {
            return {
              ...val,
              no: (currentPage - 1) * itemsPerPage + (index + 1),
            };
          });
          setFinanciersData(brand_list);
          setFilteredData(brand_list);
          const lenTrans = res[0]?.len;
          setArrayLength(lenTrans);
        } else {
          setFinanciersData([]);
        }
      });
      // }
    } catch (error) {
      errorToast(error.msg);
    }
  }, [currentPage, modal]);

  const pageCount = Math.ceil(arrayLength / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, arrayLength);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDelete = (e, rowData) => {
    e.preventDefault();
    console.log({ e, rowData });
    const form_data = new FormData();
    form_data.append("brand_id", rowData.brand_id);
    Swal.fire({
      title: "Are you sure?",
      html: `<p>You want to delete this Brand!</p>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await auctionService.DeleteBrand(form_data);
          if (response?.status === 200) {
            successToast(response?.msg);
            window.location.reload();
          } else {
            console.log(response.msg);
            errorToast(response?.msg);
          }
        } catch (error) {
          console.log(error);
          errorToast(error?.message);
        }
      },
    });
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    try {
      if (value !== "") {
        const form_data = new FormData();
        form_data.append("search", value);
        auctionService.SearchBrand(form_data).then((res) => {
          setLoading(false);
          if (res?.length >= 0) {
            const brand_list = res?.map((val, index) => {
              return {
                ...val,
                no: index + 1,
              };
            });
            console.log({ brand_list });
            setFinanciersData(brand_list);
            setFilteredData(brand_list);
            setArrayLength(0);
          } else {
            setFilteredData([]);
            errorToast(res?.msg);
          }
        });
      } else {
        setLoading(true);
        const form_data = new FormData();
        form_data.append("pg_no", currentPage);
        auctionService.GetBrand(form_data).then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            const brand_list = res?.map((val, index) => {
              return {
                ...val,
                no: (currentPage - 1) * itemsPerPage + (index + 1),
              };
            });
            setFinanciersData(brand_list);
            setFilteredData(brand_list);
            const lenTrans = res[0]?.len;
            setArrayLength(lenTrans);
          } else {
            setFinanciersData([]);
          }
        });
      }
    } catch (error) {
      errorToast(error.msg);
    }
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-2">
          <span className="d-flex justify-content-between col-2 col-sm-1 col-sm-1">
            <i
              className="bi bi-arrow-left-circle "
              onClick={() => history.push("/meratractor")}
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </span>

          <div className="col-10 col-sm-4 col-lg-3 mt-2 float-start">
            <h4 className="">Manage Brands</h4>
          </div>
          <div
            className="col-12 col-sm-3 col-lg-2 float-start"
            style={{ marginTop: "5px", marginBottom: "3px" }}
          >
            <button
              className="btn btn-primary"
              onClick={(e) => {
                setModal(true);
              }}
            >
              <span style={{ marginBottom: "1px", marginRight: "3px" }}>+</span>
              Add Brand
            </button>
          </div>
          <div
            className="col-12 col-sm-4 col-lg-6 mt-2 float-start"
            style={{ marginBottom: "4px" }}
          >
            <input
              className="form-control"
              type="text"
              name="name"
              placeholder="Search by Brand"
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>
      {modal && (
        <CreateBrandModalPopup
          show={modal}
          setModal={setModal}
          data={modalData}
          initialValue={initialValue}
          setdata={setModalData}
          onHide={() => {
            setModal(false);
            setModalData(initialValue);
          }}
        />
      )}

      {loading ? (
        <div
          className="d-flex justify-content-center container"
          style={{ minHeight: "80vh", alignItems: "center" }}
        >
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>
          <div
            className="container  table-responsive mt-2 text-center"
            style={{ minHeight: "5vh", maxHeight: "80vh" }}
          >
            <table className="table table-hover table-bordered">
              <thead className="thead-dark">
                <tr
                  className="text-center"
                  style={{ fontSize: "small", position: "sticky", top: 0 }}
                >
                  <th> Sr. No</th>
                  <th>Brands</th>
                  <th>Shorthand</th>
                  <th>Edit/Delete</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(filteredData) &&
                Object.entries(filteredData).length === 0 ? (
                  <div className="text-danger mt-2 "></div>
                ) : (
                  Object.entries(filteredData).map(
                    ([key, rowData], rowIndex) => (
                      <tr
                        style={{
                          fontSize: "small",
                        }}
                      >
                        <td>{rowData?.no}</td>
                        <td className="text-align-start">{rowData.name}</td>
                        <td className="text-align-start">
                          {rowData.shorthand}
                        </td>
                        <td>
                          <i
                            className="bi bi-pencil-square mx-3"
                            onClick={() => {
                              setModalData(rowData);
                              setModal(true);
                            }}
                            style={{ cursor: "pointer" }}
                          ></i>
                          <i
                            className="bi bi-trash mx-3"
                            style={{ cursor: "pointer" }}
                            // id={value?.id}
                            onClick={(e) => {
                              handleDelete(e, rowData);
                            }}
                          ></i>
                        </td>
                      </tr>
                    )
                  )
                )}
              </tbody>
            </table>
            {financiersData && financiersData.length === 0 && (
              <div className="text-danger text-center mt-2 ">No Data</div>
            )}

            {arrayLength > 10 && (
              <div className="d-flex justify-content-center">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {currentPage > 0 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          to="#"
                          onClick={() => paginate(1)}
                          style={{ cursor: "pointer" }}
                        >
                          First
                        </a>
                      </li>
                    )}
                    {currentPage > 0 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          to="#"
                          onClick={() =>
                            paginate(currentPage > 1 ? currentPage - 1 : 1)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {"<"}
                        </a>
                      </li>
                    )}

                    {Array.from({ length: Math.min(pageCount, 2) }, (_, i) => {
                      const pageNumber = currentPage - Math.floor(3 / 2) + i;
                      return (
                        pageNumber > 0 &&
                        pageNumber <= pageCount && (
                          <li
                            key={pageNumber}
                            className={`page-item ${
                              currentPage === pageNumber ? "active" : ""
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            <a
                              className="page-link"
                              to="#"
                              onClick={() => handlePageChange(pageNumber)}
                              style={{ cursor: "pointer" }}
                            >
                              {pageNumber}
                            </a>
                          </li>
                        )
                      );
                    })}

                    {currentPage < pageCount && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          to="#"
                          onClick={() => paginate(currentPage + 1)}
                          style={{ cursor: "pointer" }}
                        >
                          {">"}
                        </a>
                      </li>
                    )}

                    {currentPage < pageCount && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          to="#"
                          onClick={() => paginate(pageCount)}
                          style={{ cursor: "pointer" }}
                        >
                          Last
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ManageBrand;
