import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import auctionService from "../../../services/auction/auction.service";
import { errorToast } from "../../../react-toastfiy/toast";
import Select from "react-select";

const CreateStateModalPopup = (props) => {
  const { modal, data, setdata, setModal } = props;
  console.log({ data });
  const [brandList, setBrandList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [errors, setErrors] = useState({});
  console.log({ brandList, stateList });
  //   GetUserStateList;

  useEffect(() => {
    try {
      auctionService.GetUserStateList().then((res) => {
        // setLoading(false);
        console.log({ res });
        let state_list = res.states.map((val, key) => ({
          label: val,
          value: val,
        }));

        setStateList(state_list);
      });
      auctionService.GetBrandList().then((res) => {
        // setLoading(false);
        console.log({ res });
        let brand_list = res.brands.map((val, key) => ({
          label: val,
          value: val,
        }));

        setBrandList(brand_list);
      });
    } catch (error) {
      errorToast(error.msg);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const errors = {};
      if (!data?.name) errors.name = "State Name is required.";
      if (!data?.state_shorthand)
        errors.state_shorthand = "State Shorthand is required.";

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }

      const form_data = new FormData();
      form_data.append("name", data?.name);
      form_data.append("state_shorthand", data?.state_shorthand);

      auctionService.CreateState(form_data).then((res) => {
        if (res.status === 200) {
          console.log(res.msg, { res });
          setModal(false);
        } else {
          console.log({ res });
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    try {
      const errors = {};
      if (!data?.name) errors.name = "State Name is required.";
      if (!data?.state_shorthand)
        errors.state_shorthand = "State Shorthand is required.";

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      const form_data = new FormData();
      form_data.append("name", data?.name);
      form_data.append("state_shorthand", data?.state_shorthand);
      form_data.append("state_id", data?.state_id);

      auctionService.UpdateState(form_data).then((res) => {
        if (res.status === 200) {
          console.log(res.msg, { res });
          setModal(false);
          setdata();
        } else {
          console.log({ res });
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        {data?.state_id ? <h5>Update State</h5> : <h5>Create State</h5>}
      </Modal.Header>
      <Modal.Body>
        <div className="container table-responsive mt-2">
          <div className="col-12">
            <label>Name</label>
            <input
              className="form-control"
              type="text"
              name="name"
              value={data?.name}
              onChange={(e) => {
                setdata((prev) => ({ ...prev, ["name"]: e.target.value }));
              }}
              required
            />
            {errors.name && (
              <small className="text-danger">{errors.name}</small>
            )}
          </div>

          <div className="col-12">
            <label>State Shorthand</label>
            <input
              className="form-control"
              type="text"
              name="state_shorthand"
              value={data?.state_shorthand}
              onChange={(e) => {
                setdata((prev) => ({
                  ...prev,
                  ["state_shorthand"]: e.target.value,
                }));
              }}
              required
            />
            {errors.state_shorthand && (
              <small className="text-danger">{errors.state_shorthand}</small>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>
          Close
        </Button>
        {data?.state_id !== "" ? (
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        ) : (
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CreateStateModalPopup;
