import React, { useEffect, useState } from "react";

import jwtDecode from "jwt-decode";
import { useHistory, useLocation, Link } from "react-router-dom";

import Select from "react-select";
import ReactLoading from "react-loading";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";
import fillFormImf from "../../img/undraw_forms_re_pkrt.svg";
import Swal from "sweetalert2";

const AddAuctionMeratractor = () => {
  const [isScreen, setIsScreen] = useState(window.innerWidth < 500);
  const [isSmallScreen, setisSmallScreen] = useState(window.innerWidth < 380);

  const handleResize = () => {
    setIsScreen(window.innerWidth < 500);
    setisSmallScreen(window.innerWidth < 380);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const singleuploadbreadcrumb = {
    height: isSmallScreen ? "60px" : "30px",
    borderRadius: "0",
  };

  const singleuploadbreadcrumbMargin = {
    marginTop: isSmallScreen ? "-14px" : "-12px",
    marginBottom: isSmallScreen ? "14px" : "",
  };

  const token = localStorage.getItem("refreshToken");
  const history = useHistory();
  const user = jwtDecode(token);
  const phone = user?.phone;

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const auctionId = searchParams.get("uid");

  console.log({ auctionId });

  const optionAuctionType = [
    {
      value: "open",
      label: "Open",
    },
    {
      value: "online",
      label: "Online",
    },
  ];
  const initalValue = {
    segment_id: "",
    financier_id: "",
    state_id: "",
    description: "",
    auction_type: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
  };

  const [data, setData] = useState(initalValue);
  const [SEGTYPE, SETSEGTYPE] = useState([]);
  const [errors, setErrors] = useState({});
  const [financier, setFinancier] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [segmentLoading, setSegmentLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    try {
      setLoading(true);
      setSegmentLoading(true);
      auctionService.GetSegmentType().then((res) => {
        if (res.status === 200) {
          let seg_type = res?.data?.map((value, key) => ({
            label: value.name,
            value: value,
          }));
          if (res.data.length !== 0) {
            SETSEGTYPE(seg_type);
          }
        } else {
          setLoading(false);
        }
      });
      const form_data = new FormData();
      form_data.append("phone", phone);
      auctionService.GetFinanciersList(form_data).then((res) => {
        if (res.status === 200) {
          setLoading(false);
          let financier_option = res?.data?.map((value, key) => ({
            label: `${value.name} ${value.state_name}`,
            value: value,
          }));
          if (res?.data?.length !== 0) {
            setSegmentLoading(false);
            setFinancier(financier_option);
          }
        } else {
          setLoading(false);
        }
      });
    } catch (error) {}
  }, []);

  useEffect(() => {
    try {
      if (
        financier &&
        financier?.length > 0 &&
        auctionId !== undefined &&
        auctionId !== ""
      ) {
        setUserLoading(true);
        const form_data = new FormData();
        form_data.append("phone", phone);
        form_data.append("uid", auctionId);

        auctionService.GetAssetByAssetId(form_data).then((res) => {
          const State_name = financier?.find(
            (val) => val.value.state_id === res.state_id
          );

          const start_times = res?.start_date
            ? res?.start_date.split(" ")[1]
            : "";

          const end_times = res?.end_date ? res?.end_date.split(" ")[1] : "";

          const start_dates = res?.start_date
            ? res?.start_date.split(" ")[0]
            : "";

          const end_dates = res?.end_date ? res?.end_date.split(" ")[0] : "";

          const newRes = {
            ...res,
            state_name: State_name?.value?.state_name,
            start_time: start_times,
            start_date: start_dates,
            end_time: end_times,
            end_date: end_dates,
          };

          if (Object.entries(res) && Object.entries(res)?.length > 0) {
            setUserLoading(false);
            setData(newRes);
          } else {
            setUserLoading(false);
          }
        });
      }
    } catch (error) {}
  }, [financier]);

  const handleChange = (e, name) => {
    setData((prev) => ({
      ...prev,
      [name]: e.value.id,
    }));
  };
  const handleChangeFinancier = (e, name) => {
    setData((prev) => ({
      ...prev,
      ["financier_id"]: e.value.id,
      state_name: e.value.state_name,
      state_id: e.value.state_id,
    }));
  };

  const handleSubmit = (e, type) => {
    e.preventDefault();
    console.log({ type, auctionId });
    try {
      setButtonLoading(true);

      const {
        segment_id,
        financier_id,
        state_id,
        description,
        auction_type,
        start_date,
        end_date,
      } = data;

      const errors = {};
      if (!segment_id) errors.segment_id = "Segment is required";
      if (!financier_id) errors.financier_id = "Financier is required";
      if (!state_id) errors.state_id = "State is required";
      if (!description) errors.description = "Description is required";
      if (!auction_type) errors.auction_type = "Auction Type is required";
      if (!start_date) errors.start_date = "Start Date is required";
      if (!end_date) errors.end_date = "End Date is required";

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }

      if (auctionId) {
        const form_data = new FormData();
        if (data.viewable === "on" || data.viewable === true) {
          form_data.append("viewable", "on");
        }
        form_data.append("uid", auctionId);
        form_data.append("phone", phone);
        form_data.append("segment", data.segment_id);
        form_data.append("financier", data.financier_id);
        form_data.append("description", data.description);
        form_data.append("auction_type", data.auction_type);
        form_data.append("start_date", data.start_date);
        form_data.append("start_time", data.start_time);
        form_data.append("end_date", data.end_date);
        form_data.append("end_time", data.end_time);
        form_data.append("state", data.state_name);

        auctionService.UpdateAssetByAssetId(form_data).then((res) => {
          setButtonLoading(false);
          if (res.status === 200) {
            Swal.fire({
              title: "Auction Updated Successfully",
              // html: `<p>You want to edit auction !</p>`,
              icon: "success",
              // showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
              showLoaderOnConfirm: true,
              preConfirm: async () => {
                try {
                  if (res?.status === 200) {
                    const formData = new FormData();
                    formData.append("uid", auctionId);
                    auctionService.RemoveCardAPI(formData).then((response) => {
                      history.push(
                        `/meratractor/auctions/view/assets?uid=${auctionId}`
                      );
                    });
                  }
                } catch (error) {
                  console.log(error);
                  errorToast(error?.message);
                }
              },
            });
          } else {
            errorToast(res?.msg);
          }
        });
      } else {
        const form_data = new FormData();
        if (data.viewable === "on") {
          form_data.append("viewable", data.viewable);
        }
        form_data.append("phone", phone);
        form_data.append("segment", data.segment_id);
        form_data.append("financier", data.financier_id);
        form_data.append("description", data.description);
        form_data.append("auction_type", data.auction_type);
        form_data.append("start_date", data.start_date);
        form_data.append("start_time", data.start_time);
        form_data.append("end_date", data.end_date);
        form_data.append("end_time", data.end_time);
        form_data.append("state", data.state_name);
        auctionService.CreatAuction(form_data).then((res) => {
          setButtonLoading(false);
          if (res.status === 200) {
            Swal.fire({
              title: "Auction Added Successfully",
              // text: "You clicked the button!",
              icon: "success",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
              showLoaderOnConfirm: true,
            }).then(() => {
              history.push(`/meratractor/auctions/modify?uid=${res.response}`);
            });
          } else {
            errorToast(res.msg);
          }
        });
      }
    } catch (error) {
      errorToast(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (data.start_date) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

      const inputDate = new Date(data.start_date);
      if (
        inputDate.getFullYear() !== currentYear ||
        inputDate < threeMonthsAgo
      ) {
        const correctedDate = new Date(
          currentYear,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        setData((prev) => ({
          ...prev,
          start_date: correctedDate.toISOString().split("T")[0],
        }));
      }
    }
  }, [data?.start_date]);

  const handleChangeEndDate = (e) => {
    e.preventDefault();
    const currentDate = new Date();
    const threeMonthsLater = new Date();
    threeMonthsLater.setMonth(currentDate.getMonth() + 3);
    const inputDate = new Date(e.target.value);
    if (inputDate < currentDate || inputDate > threeMonthsLater) {
      setData((prev) => ({
        ...prev,
        end_date: currentDate.toISOString().split("T")[0],
      }));
    } else {
      setData((prev) => ({
        ...prev,
        end_date: e.target.value,
      }));
    }
  };

  const handleChangeStartDate = (e) => {
    e.preventDefault();
    const currentDate = new Date();
    const threeMonthsLater = new Date();
    threeMonthsLater.setMonth(currentDate.getMonth() + 3);
    const inputDate = new Date(e.target.value);
    if (inputDate < currentDate || inputDate > threeMonthsLater) {
      setData((prev) => ({
        ...prev,
        start_date: currentDate.toISOString().split("T")[0],
      }));
    } else {
      setData((prev) => ({
        ...prev,
        start_date: e.target.value,
      }));
    }
  };

  useEffect(() => {
    if (auctionId !== undefined && auctionId !== "" && auctionId !== null) {
      document.title = "Meratractor | Edit auction";
    } else {
      document.title = "Meratractor | Add auction";
    }
  }, []);

  useEffect(() => {
    if (user.role === "Auction Back Office") {
      setUrl(`/meratractor/auction-home}`);
    } else if (user.role === "Auction Super Admin") {
      setUrl(`/meratractor/auction-home`);
    } else if (user.role === "Auction Representative") {
      setUrl("/auction_representative/meratractor/auction-home");
    } else {
      setUrl(`/meratractor/auction-home`);
    }
  }, []);
  console.log({ url });

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-3 mb-2">
          <span className="d-flex justify-content-between col-1">
            <i
              className="bi bi-arrow-left-circle "
              onClick={() => history.push(url)}
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </span>

          <div className="col-10 mt-2 float-start ms-2">
            {auctionId !== undefined &&
            auctionId !== "" &&
            auctionId !== null ? (
              <h4 className="">Edit Auction</h4>
            ) : (
              <h4 className="">Add Auction</h4>
            )}
          </div>
        </div>
      </div>

      {loading || userLoading || segmentLoading ? (
        <div className="d-flex justify-content-center">
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={10}
            width={55}
          />
        </div>
      ) : (
        <div className="container d-flex d-flex-wrap justify-content-between ">
          <div
            className={`col-md-7 col-12 rounded ${!isScreen ? " bg-body" : ""}`}
          >
            <form className="ms-auto" onSubmit={handleSubmit}>
              <div className="form-check form-switch d-flex justify-content-center">
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  // checked
                  // checked
                  checked={data.viewable == true || data.viewable == "on"}
                  onChange={(e) =>
                    setData((prev) => {
                      if (e.target.checked) {
                        return { ...prev, viewable: "on" };
                      } else {
                        const { viewable, ...rest } = prev;
                        return rest;
                      }
                    })
                  }
                />
                <label
                  className="form-check-label"
                  for="flexSwitchCheckChecked"
                >
                  Viewable
                </label>
              </div>

              {auctionId !== undefined &&
                auctionId !== "" &&
                auctionId !== null && (
                  <>
                    <label for="description">Unique ID</label>
                    <div className="form-group d-flex mt-2">
                      <div className="col-12 mb-2">
                        <input
                          className="form-control"
                          placeholder="Description"
                          type="text"
                          value={auctionId}
                          disabled
                        />
                      </div>
                    </div>
                  </>
                )}

              <div className="row mt-3">
                <div className="col-md-6 mb-3 form-group">
                  <label for="segment">Segment</label>
                  <Select
                    id="segment"
                    options={SEGTYPE}
                    onChange={(e) =>
                      setData((prev) => ({
                        ...prev,
                        ["segment_id"]: e.value.id,
                      }))
                    }
                    value={SEGTYPE?.find(
                      (option) => option?.value.id === data?.segment_id
                    )}
                    placeholder="Segment"
                    required
                    isLoading={loading}
                  />
                  {errors.segment_id && (
                    <div className="text-danger">{errors.segment_id}</div>
                  )}
                </div>
                <div className="col-md-6 form-group">
                  <label for="financier">Financier</label>
                  <Select
                    id="financier"
                    options={financier}
                    onChange={(e) => handleChangeFinancier(e, "financier")}
                    value={financier?.find(
                      (option) => option.value.id === data.financier_id
                    )}
                    placeholder="Financier"
                    required
                    isLoading={segmentLoading}
                  />
                  {errors.financier_id && (
                    <div className="text-danger">{errors.financier_id}</div>
                  )}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6 mb-3 form-group">
                  <label for="state">State</label>
                  <input
                    className="form-control"
                    placeholder="State"
                    id="state"
                    type="text"
                    name="state"
                    value={data.state_name}
                    disabled
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label for="description">Description</label>
                  <input
                    className="form-control"
                    placeholder="Description"
                    id="description"
                    type="text"
                    name="description"
                    value={data?.description}
                    onChange={(e) =>
                      setData((prev) => ({
                        ...prev,
                        ["description"]: e?.target?.value,
                      }))
                    }
                    required
                  />

                  {errors.description && (
                    <div className="text-danger">{errors.description}</div>
                  )}
                </div>
              </div>

              <div className="form-group d-flex mt-2">
                <div className="col-12 mb-2">
                  <label for="auction_type">Auction Type</label>
                  <Select
                    id="auction_type"
                    options={optionAuctionType}
                    onChange={(e) =>
                      setData((prev) => ({
                        ...prev,
                        ["auction_type"]: e?.label,
                      }))
                    }
                    value={optionAuctionType?.find(
                      (option) => option.label === data?.auction_type
                    )}
                    placeholder="Auction Type "
                    required
                    isLoading={segmentLoading}
                  />
                  {errors.auction_type && (
                    <div className="text-danger">{errors.auction_type}</div>
                  )}
                </div>
              </div>

              <label
                mlFor="start_date"
                className="label"
                style={{ display: "felx !important" }}
              >
                Start Date
              </label>
              <div className="form-group d-flex mt-2">
                <div className="col-7 col-sm-7 col-md-6 col-lg-6">
                  <input
                    type="date"
                    className="form-control"
                    id="start_date"
                    name="start_date"
                    placeholder="Start Date"
                    value={data?.start_date}
                    onChange={handleChangeStartDate}
                    required
                  />

                  {errors.start_date && (
                    <div className="text-danger">{errors.start_date}</div>
                  )}
                </div>
                <div className="col-5 col-sm-5 col-md-6 col-lg-6 ms-1">
                  <input
                    type="time"
                    className="form-control"
                    id="start_time"
                    name="time"
                    placeholder="Start Date"
                    value={data?.start_time}
                    onChange={(e) =>
                      setData((prev) => ({
                        ...prev,
                        ["start_time"]: e?.target?.value,
                      }))
                    }
                    required
                  />

                  {errors.start_time && (
                    <div className="text-danger">{errors.start_time}</div>
                  )}
                </div>
              </div>

              <label
                mlFor="end_date"
                className="label"
                style={{ display: "felx !important" }}
              >
                End Date
              </label>
              <div className="form-group d-flex mt-2">
                <div className="col-7 col-sm-7 col-md-6 col-lg-6">
                  <input
                    type="date"
                    className="form-control"
                    id="end_date"
                    name="end_date"
                    placeholder="Start Date"
                    value={data?.end_date}
                    onChange={handleChangeEndDate}
                    // disabled={
                    //   data?.end_date === "" &&
                    //   auctionId !== undefined &&
                    //   auctionId !== ""
                    // }
                    required
                  />
                  {errors.end_date && (
                    <div className="text-danger">{errors.end_date}</div>
                  )}
                </div>
                <div className="col-5 col-sm-5 col-md-6 col-lg-6 ms-1">
                  <input
                    type="time"
                    className="form-control"
                    id="end_time"
                    name="time"
                    placeholder="Start Date"
                    value={data?.end_time}
                    onChange={(e) =>
                      setData((prev) => ({
                        ...prev,
                        ["end_time"]: e?.target?.value,
                      }))
                    }
                    required
                  />
                  {errors.end_time && (
                    <div className="text-danger">{errors.end_time}</div>
                  )}
                </div>
              </div>
              {auctionId !== undefined &&
              auctionId !== "" &&
              auctionId !== null ? (
                <div className="d-flex  justify-content-center  mt-4">
                  {buttonLoading ? (
                    <button
                      className="btn btn-primary"
                      type="button"
                      disabled
                      id="loadingBtnTp"
                    >
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary  col-2"
                      type="edit"
                      onClick={(e) => handleSubmit(e, "edit")}
                      disabled={
                        data?.segment_id === "" &&
                        data?.financier_id === "" &&
                        data?.state_id === "" &&
                        data?.description === "" &&
                        data?.auction_type === "" &&
                        data?.start_date === "" &&
                        data?.end_date === "" &&
                        auctionId !== undefined &&
                        auctionId === ""
                      }
                    >
                      Update
                    </button>
                  )}
                </div>
              ) : (
                <div className="d-flex  justify-content-center mt-4">
                  {buttonLoading ? (
                    <button
                      className="btn btn-primary"
                      type="button"
                      disabled
                      id="loadingBtnTp"
                    >
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary  col-2"
                      type="submit"
                      disabled={
                        data?.segment_id === "" &&
                        data?.financier_id === "" &&
                        data?.state_id === "" &&
                        data?.description === "" &&
                        data?.auction_type === "" &&
                        data?.start_date === "" &&
                        data?.end_date === ""
                      }
                    >
                      Submit
                    </button>
                  )}
                </div>
              )}
            </form>
          </div>
          <div className="ms-2">
            <img
              src={fillFormImf}
              alt="Fill Form Img"
              width="80%"
              height="80%"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AddAuctionMeratractor;
