import React, { useEffect } from "react";
import CardBlock from "../../../../common/CardBlock";
import ViewAllTransaction from "../../../../img/all_txn.svg";
import UploadMultipleTransaction from "../../../../img/upload_multi_txn.svg";
import ErrorHandler from "../../../../common/ErrorHandler";
import { Link, useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";

const MultiUserTransactionHome = () => {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);
  const history = useHistory();
  useEffect(() => {
    document.title = "Home";
  }, []);

  try {
    return (
      <>
        <div className="container-fluid shadow-sm">
          <div className="container">
            <div className="row">
              <div className="d-sm-block d-md-none d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                  </div>
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-block d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12 col-md-6 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-6 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-none d-lg-block d-xl-block">
                <div className="row">
                  <div className="col-12 col-md-8 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-4 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container responsive mt-2 ms-auto ">
          <div className="row mt-3 mb-2">
            <span className="d-flex justify-content-between col-1">
              <i
                className="bi bi-arrow-left-circle "
                onClick={() => history.push("/meratractor/wallet-home")}
                style={{
                  fontSize: "xx-large",
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              ></i>
            </span>
            <div className="col-10 mt-2 float-start ms-2">
              <h4 className="">Multiple User Transetion</h4>
            </div>
          </div>
        </div>

        <div className="container container-body">
          <CardBlock
            name="View All Transactions"
            logo={ViewAllTransaction}
            to="/meratractor/wallet/all-transactions"
          />
          <CardBlock
            name="Upload Multiple Transaction"
            logo={UploadMultipleTransaction}
            to="/meratractor/wallet/upload-multiple-transactions"
          />
        </div>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
};

export default MultiUserTransactionHome;
