import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import CardBlock from "../../common/CardBlock";
import AddAuction from "./../../img/add_assets.svg";
import AuctionSummary from "./../../img/summary.svg";
import ExcelImg from "./../../img/upload_asset_excel.png";
import ACR from "./../../img/user_balance_list.svg";
import ViewAssets from "./../../img/view-assests.svg";
import EditAuctionDetails from "./../../img/view.svg";
import AutobidData from "./../../img/autobid_data.svg";
import jwtDecode from "jwt-decode";

const EditAuctionHome = () => {
   const currentUser = localStorage.getItem("refreshToken");
   const user = jwtDecode(currentUser);
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const auctionId = searchParams.get("uid");
    const [url, setUrl] = useState(null);

  useEffect(() => {
    document.title = "Modify Auction";
  }, []);

    useEffect(() => {
      if (user.role === "Auction Representative") {
        setUrl("/auction_representative/auction_home/auction");
      } else {
        setUrl("/meratractor/auction/view-auction");
      }
    }, []);

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container responsive mt-2 ">
        <div className="row mt-3 mb-2">
          <div className="d-flex justify-content-between col-1">
            <i
              className="bi bi-arrow-left-circle"
              onClick={() => history.push(url)}
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </div>
          <div className="col-10 mt-2 float-start ms-2">
            <h5 className="">Auction ID : {auctionId}</h5>
          </div>
        </div>
      </div>

      <div className="container container-body">
        <CardBlock
          name="Add Assets"
          logo={AddAuction}
          to={`/meratractor/auctions/add_assets?uid=${auctionId}`}
        />
        <CardBlock
          name="Edit Auction Details"
          logo={EditAuctionDetails}
          to={`/meratractor/auctions/edit?uid=${auctionId}`}
        />
        <CardBlock
          name="View Assets"
          logo={ViewAssets}
          to={`/meratractor/auctions/view/assets?uid=${auctionId}`}
        />
        <CardBlock
          name="Auction Summary"
          logo={AuctionSummary}
          to={`/meratractor/auctions/auction_summary?uid=${auctionId}`}
        />
        {/* <CardBlock
          name="Asset Status"
          logo={AuctionSummary}
          to={`/meratractor/auctions/asset-status:${asset_id}`}
        /> */}
        <CardBlock
          name="Autobid Data"
          logo={AutobidData}
          to={`/meratractor/auctions/view/view_auction_autobid?uid=${auctionId}`}
        />
        <CardBlock
          name="ACR"
          logo={ACR}
          to={`/meratractor/auctions/acr?uid=${auctionId}`}
        />
        <CardBlock
          name="Upload Bulk Images"
          logo={ExcelImg}
          to={`/meratractor/auctions/upload_asset_excel?uid=${auctionId}`}
        />
      </div>
    </>
  );
};

export default EditAuctionHome;
