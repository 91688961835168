import React, { useEffect, useState } from "react";
import CardBlock from "../../../common/CardBlock";
import SingleTNX from "../../../img/single_tnx.svg";
import MultiTNX from "../../../img/multi_tnx.svg";
import UserBalanceList from "../../../img/user_balance_list.svg";
import UserNotLInkedToWallet from "../../../img/not_linked_to_wallet.svg";
import ErrorHandler from "../../../common/ErrorHandler";
import { Link, useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";

const WalletHome = () => {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);
  const history = useHistory();
  const [url, setUrl] = useState(null);
  useEffect(() => {
    document.title = "Home";
  }, []);

  useEffect(() => {
    if (user.role === "") {
      setUrl(
        `/auction_representative/user_home:${user.phone}&state=${user.state}`
      );
    } else if (user.role === "Auction Representative") {
       setUrl(
         `/auction_representative/user_home:${user.phone}&state=${user.state}`
       );
    } else {
      setUrl("/meratractor");
    }
  }, []);
  console.log({user})

  try {
    return (
      <>
        <div className="container-fluid shadow-sm">
          <div className="container">
            <div className="row">
              <div className="d-sm-block d-md-none d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                  </div>
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-block d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12 col-md-6 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-6 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-none d-lg-block d-xl-block">
                <div className="row">
                  <div className="col-12 col-md-8 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-4 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container responsive mt-2 ms-auto ">
          <div className="row mt-3 mb-2">
            <span className="d-flex justify-content-between col-1">
              <i
                className="bi bi-arrow-left-circle"
                onClick={() => history.push(url)}
                style={{
                  fontSize: "xx-large",
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              ></i>
            </span>
            <div className="col-10 mt-2 float-start ms-2">
              <h5 className="mt-2">Wallet Home</h5>
            </div>
          </div>
        </div>

        <div className="container container-body">
          <CardBlock
            name="Single User Transactions"
            logo={SingleTNX}
            to="/meratractor/wallet/single-user-txn"
          />
          <CardBlock
            name="Multiple User Transactions"
            logo={MultiTNX}
            to="/meratractor/wallet/multi-user-txn"
          />
          <CardBlock
            name="User Balance List"
            logo={UserBalanceList}
            to="/meratractor/wallet/all-user-balance"
          />
          <CardBlock
            name="User Not Linked with wallet"
            logo={UserNotLInkedToWallet}
            to="/meratractor/wallet/wallet-not-linked"
          />
        </div>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
};

export default WalletHome;
