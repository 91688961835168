import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Link, useLocation, useHistory } from "react-router-dom";
import DatePicker from "../../../../common/DatePicker";
import SearchBox from "../../../../common/SearchBox";
import { formatDate } from "../../../../helpers/functions/dateFormatter";
import auctionService from "../../../../services/auction/auction.service";
import jwtDecode from "jwt-decode";
import { errorToast } from "../../../../react-toastfiy/toast";

const ViewTransactionOfUsers = () => {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);
  const history = useHistory();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 430);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const singleuploadbreadcrumb = {
    height: isSmallScreen ? "85px" : "30px",
    borderRadius: "0",
    marginLeft: isSmallScreen ? "0px" : "auto",
    marginBottom: isSmallScreen ? "12px" : "",
  };

  const singleuploadbreadcrumbMargin = {
    marginTop: isSmallScreen ? "-14px" : "-12px",
    marginBottom: isSmallScreen ? "14px" : "",
  };

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 430);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const singleuploadbreadcrumb = {
  //   marginLeft: isSmallScreen ? "0px" : "auto",
  //   marginBottom: isSmallScreen ? "12px" : "",
  // };

  const location = useLocation();
  let initialValue = {
    phone: "",
    name: "",
  };

  const headers = [
    { header: "Date", dataKey: "date" },
    { header: "Description", dataKey: "description" },
    { header: "Amount", dataKey: "amount" },
    { header: "Balance", dataKey: "balance" },
    { header: "	Status", dataKey: "status" },
  ];

  const [data, setData] = useState(initialValue);
  const [transactionData, setTransactionData] = useState([]);
  const [filterTransactionData, setFilterTransactionData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [fetchData, setFetchData] = useState(false);
  const [namefield, setNameField] = useState(false);
  const [phoneField, setPhoneField] = useState(false);

  useEffect(() => {
    const monthly_summary_start_date =
      location?.state?.monthly_summary_start_date;
    const monthly_summary_end_date = location?.state?.monthly_summary_end_date;
    if (monthly_summary_start_date && monthly_summary_end_date) {
      setStartDate(monthly_summary_start_date);
      setEndDate(monthly_summary_start_date);
    } else {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();
      const date = currentDate.getDate();

      let firstDayOfMonth = formatDate(new Date(year, month, date));
      let lastDayOfMonth = formatDate(new Date(year, month - 1));
      setStartDate(lastDayOfMonth);
      setEndDate(firstDayOfMonth);
    }
  }, []);

  const handleChange = (e, name, index) => {
    e.preventDefault();
    const { value } = e.target;
    const phoneLength = data?.phone?.length ?? 0;
    const nameLength = data?.name?.length ?? 0;
    console.log({ value, nameLength });
    if (name === "phone" && phoneLength <= 3) {
      const parts = value.split("-").map((part) => part.trim());
      const nameValue = parts[1];
      const phoneValue = parts[0] || "";
      console.log({ nameValue, phoneValue });
      setData((prev) => ({
        ...prev,
        ["name"]: nameValue,
        ["phone"]: phoneValue,
      }));
      setLoading(true);
      name === "phone" ? setPhoneField(true) : setNameField(true);
      setSuggestions([]);
    } else if (name === "name" && nameLength <= 3) {
      const parts = value.split("-").map((part) => part.trim());
      const nameValue = parts[0];
      const phoneValue = parts[1] || "";
      console.log({ nameValue, phoneValue });
      setData((prev) => ({
        ...prev,
        ["phone"]: phoneValue,
        ["name"]: nameValue,
      }));
      setLoading(true);
      setSuggestions([]);
      name === "phone" ? setPhoneField(true) : setNameField(true);
    } else if (name === "phone" && phoneLength >= 4) {
      const parts = value.split("-").map((part) => part.trim());
      const nameValue = parts[1];
      const phoneValue = parts[0] || "";
      console.log({ nameValue, phoneValue });
      setData((prev) => ({
        ...prev,
        ["phone"]: phoneValue,
        ["name"]: nameValue,
      }));

      setLoading(true);
      const form_data = new FormData();
      form_data.append("phone", phoneValue);
      auctionService.GetUserList(form_data).then((res) => {
        setLoading(false);
        if (Object.entries(res)?.length > 0) {
          const suggestions_Data = Object.values(res);
          setSuggestions(suggestions_Data);
          setData((prev) => ({
            ...prev,
            ["name"]: suggestions_Data[0]?.name,
          }));
        }
      });
      setSuggestions([]);
    } else if (name === "name" && nameLength >= 3) {
      const parts = value.includes("-")
        ? value.split("-").map((part) => part.trim())
        : [value.trim(), ""];
      console.log({ parts });
      const nameValue = parts[0];
      const phoneValue = parts[1] || "";
      console.log({ nameValue, phoneValue });
      setData((prev) => ({
        ...prev,
        ["name"]: nameValue,
        ["phone"]: phoneValue,
      }));
      const form_data = new FormData();
      form_data.append("name", nameValue);
      auctionService.GetUserList(form_data).then((res) => {
        setLoading(false);
        if (Object.entries(res)?.length > 0) {
          const suggestions_Data = Object.values(res);
          setSuggestions(suggestions_Data);
          if (phoneValue === "") {
            setData((prev) => ({
              ...prev,
              ["phone"]: suggestions_Data[0]?.phone,
            }));
          }
        }
      });
      setLoading(true);
      setSuggestions([]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFetchData(true);
    setButtonLoading(true);
    const form_data = new FormData();
    form_data.append("number", data?.phone);
    form_data.append("sdate", startDate);
    form_data.append("edate", endDate);
    auctionService.GetTransactionByUser(form_data).then((res) => {
      setButtonLoading(false);
      const new_Data = Object.values(res);
      if (!res.msg && new_Data.length > 0) {
        setTransactionData(new_Data);
        setFilterTransactionData(new_Data);
      } else {
        errorToast(res?.msg)
        setTransactionData([]);
      }
    });
  };

  const generatePDF = (e, userData) => {
    e.preventDefault();

    let userPhone = data.phone;
    let userName = data.name;
    console.log({ userName, userPhone });

    const doc = new jsPDF({
      orientation: "landscape",
    });

    doc.setFontSize(12);
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 10;
    let yPos = margin;
    let initialYPos = yPos;

    const columns = [
      "Sr. No.",
      "Date",
      "Description",
      "Amount",
      "Balance",
      "Status",
    ];

    const colWidths = {
      "Sr. No.": 15,
      Date: 30,
      Description: 110,
      Amount: 40,
      Balance: 40,
      Status: 30,
    };

    const drawTableHeaders = (xPos) => {
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.setFillColor(40, 167, 69); // Green background for headers
      doc.setTextColor(255);
      columns.forEach((column) => {
        doc.setLineWidth(0.1);
        doc.setDrawColor(0);
        doc.setFillColor(40, 167, 69);
        const textWidth =
          (doc.getStringUnitWidth(column) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        const textX = xPos + (colWidths[column] - textWidth) / 2;
        const textY = yPos + margin / 2 + 3;
        doc.rect(xPos, yPos, colWidths[column], margin, "F");
        doc.text(textX, textY, column);
        xPos += colWidths[column];
      });

      yPos += margin;
    };

    const drawTableRows = () => {
      let fill = false; // Flag to alternate row colors
      userData.forEach((row, key) => {
        initialYPos = yPos;

        if (yPos + margin > pageHeight - margin) {
          doc.addPage();
          yPos = margin;
          drawTableHeaders(margin);
        }

        let xPos = margin;
        const values = [
          key + 1,
          row.date,
          row.description,
          row.amount,
          row.balance,
          row.status,
        ];

        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);

        values.forEach((value, index) => {
          let textToPrint = value.toString();
          const column = index === 2 ? "Description" : columns[index];
          const colWidth = colWidths[column];

          const textWidth =
            (doc.getStringUnitWidth(textToPrint) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          const textX = xPos + (colWidth - textWidth) / 2;

          const splitText = doc.splitTextToSize(textToPrint, colWidth);
          const lineHeight =
            doc.internal.getLineHeight() / doc.internal.scaleFactor;
          const textHeight = splitText.length * lineHeight;

          // Alternate row background color
          if (fill) {
            doc.setFillColor(245, 245, 245); // Light gray
          } else {
            doc.setFillColor(255, 255, 255); // White
          }

          doc.rect(xPos, yPos, colWidth, textHeight + margin, "F");
          doc.setTextColor(0);
          doc.text(textX, yPos + margin / 2 + lineHeight / 2, splitText);
          xPos += colWidth;
        });

        fill = !fill; // Toggle row fill color

        yPos += margin;
      });
    };

    drawTableHeaders(margin);
    drawTableRows();

    doc.save(
      `User_Transactions_${userName}_${userPhone}_${startDate}_${endDate}.pdf`
    );
  };

  const generateExcel = (e, data) => {
    e.preventDefault();

    let csvContent = "data:text/csv;charset=utf-8,";

    const columns = [
      "Sr. No.",
      "Date",
      "Description",
      "Amount",
      "Balance",
      "Status",
    ];

    csvContent += columns.join(",") + "\n";

    data.forEach((row, index) => {
      const values = [
        index + 1,
        row.date,
        row.description,
        row.amount,
        row.balance,
        row.status,
      ];

      const adjustedValues = values.map((value) => {
        return `"${value.toString().replace(/"/g, '""')}"`;
      });
      csvContent += adjustedValues.join(",") + "\n";
    });

    const encodedUri = encodeURI(csvContent);

    const link = document.createElement("a");

    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "View_User_Transactions.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-3 mb-2">
          <span className="d-flex justify-content-between col-1">
            <i
              className="bi bi-arrow-left-circle "
              onClick={() =>
                history.push("/meratractor/wallet/single-user-txn")
              }
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </span>
        </div>
      </div>

      <div className="container d-flex d-flex-wrap justify-content-between ">
        <div
          className={
            isSmallScreen
              ? "col-md-8  col-12 bg-body p-3 mb-2  rounded"
              : "col-md-7  col-12 bg-body p-3 mb-2  rounded"
          }
        >
          <form className="ms-auto" onSubmit={handleSubmit}>
            <div className="row mt-2 ">
              <div className="col-md-6 mb-2 form-group">
                <label for="phone">Phone</label>
                <input
                  className="form-control"
                  type="number"
                  list="phone"
                  placeholder="Phone"
                  value={data.phone}
                  onChange={(e) => {
                    handleChange(e, "phone");
                  }}
                  required
                  isDisabled={loading}
                />
                {phoneField && (
                  <datalist id="phone">
                    {suggestions &&
                      suggestions.length >= 0 &&
                      suggestions.map((value, key) => (
                        <option value={value?.phone}>
                          {value.name} - {value.phone}
                        </option>
                      ))}
                  </datalist>
                )}
              </div>
              <div className="col-md-6 mb-2 form-group">
                <label for="name">Name</label>
                <input
                  className="form-control"
                  type="text"
                  list="name"
                  placeholder="Name"
                  value={data?.name}
                  onChange={(e) => {
                    handleChange(e, "name");
                  }}
                  required
                  isDisabled={loading}
                />

                {namefield && (
                  <datalist id="name">
                    {suggestions &&
                      suggestions.length >= 0 &&
                      suggestions.map((value, key) => (
                        <option value={`${value.name}- ${value?.phone}`}>
                          {value?.name} - {value?.phone}
                        </option>
                      ))}
                  </datalist>
                )}
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-12 col-sm-6  mb-2">
                <DatePicker
                  date={startDate}
                  id="start_date"
                  name="start_date"
                  placeholder="Start Date"
                  setDate={(e) => {
                    setStartDate((prev) => e);
                  }}
                  label="Start Date"
                  // isRequired={false}
                  required
                />
              </div>

              <div className="col-12 col-sm-6 mb-1">
                <DatePicker
                  date={endDate}
                  id="end_date"
                  name="end_date"
                  placeholder="End Date"
                  setDate={(e) => {
                    setEndDate((prev) => e);
                  }}
                  label="End Date"
                  // isRequired={false}
                  required
                />
              </div>
            </div>

            <div className="d-flex  justify-content-center  mt-4">
              {buttonLoading ? (
                <button
                  className="btn btn-primary mt-2"
                  type="button"
                  disabled
                  id="loadingBtnTp"
                >
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="btn btn-primary  col-4 col-lg-3"
                  type="submit"
                  // onClick={handleSubmit}
                  disabled={loading}
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>

      {buttonLoading && fetchData ? (
        <div
          className="d-flex justify-content-center container"
          style={{ minHeight: "80vh", alignItems: "center" }}
        >
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>
          {fetchData === true && (
            <>
              <div className="container">
                <div className="row ">
                  <hr className="soften mt-2" />
                  <div
                    className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                    style={{ marginLeft: "2px", marginBottom: "2px" }}
                  >
                    Download :
                    <span>
                      <Link
                        className="ms-4 me-3"
                        onClick={(e) => {
                          generatePDF(e, filterTransactionData);
                        }}
                      >
                        PDF
                      </Link>
                      |
                      <Link
                        className="ms-3"
                        onClick={(e) => {
                          generateExcel(e, filterTransactionData);
                        }}
                      >
                        Excel
                      </Link>
                    </span>
                  </div>
                  <div
                    className="col-md-4 col-lg-3 col-12"
                    style={singleuploadbreadcrumb}
                  >
                    <SearchBox
                      allData={transactionData}
                      setFilteredData={setFilterTransactionData}
                    />
                  </div>
                </div>
              </div>

              <div
                className="container  table-responsive mt-2 text-center"
                style={{ minHeight: "5vh", maxHeight: "80vh" }}
              >
                <table className="table table-hover table-bordered">
                  <thead className="thead-dark">
                    <tr
                      className="text-center"
                      style={{ fontSize: "small", position: "sticky", top: 0 }}
                    >
                      <th> Sr. No</th>
                      {headers.map((column) => (
                        <th className="col" key={column.header}>
                          {column.header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(filterTransactionData) &&
                    Object.entries(filterTransactionData).length === 0 ? (
                      <div className="text-danger mt-2 "></div>
                    ) : (
                      Object.entries(filterTransactionData).map(
                        ([key, rowData], rowIndex) => (
                          <tr style={{ fontSize: "small" }}>
                            <td>{rowIndex + 1}</td>
                            <td className="text-align-start">
                              {rowData?.date}
                            </td>
                            <td className="text-align-start">
                              {rowData?.description}
                            </td>
                            <td>{rowData?.amount}</td>
                            <td>{rowData?.balance}</td>
                            <td>{rowData?.status}</td>
                          </tr>
                        )
                      )
                    )}
                  </tbody>
                </table>
                {transactionData && transactionData?.length === 0 && (
                  <div className="text-danger text-center mt-2 ">No Data</div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ViewTransactionOfUsers;
