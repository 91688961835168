import React, { useEffect, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";
import * as XLSX from "xlsx";
import jwtDecode from "jwt-decode";
import uploadAssetImage from "../../img/undraw_upload_image_re_svxx.svg";

const UploadImageExcel = () => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  useEffect(() => {
    document.title = "Upload Images Excel";
  }, []);
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const asset_id = searchParams.get("uid");
  console.log({ asset_id });
  const [data, setData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = (e, type) => {
    e.preventDefault();
    console.log({ asset_id });
    try {
      setButtonLoading(true);
      const form_data = new FormData();
      form_data.append("asset_excel", data.file);
      form_data.append("uid", asset_id);
      auctionService.UploadImage(form_data).then((res) => {
        console.log({ res });
        setButtonLoading(false);
        if (res?.status === 200) {
          successToast(res?.msg);
          history.push(`/meratractor/auctions/view/assets?uid=${asset_id}`);
        } else {
          errorToast(res?.msg);
        }
      });
    } catch (error) {
      errorToast(error);
      console.log(error.msg);
    }
  };

  const downloadExcel = () => {
    const data = [
      [" ref_no 1 ", "Link 1", "link 2"],
      [" ref_no 2", "Link 1", "Link 2"],
    ];

    // Transpose the data array
    const transposedData = data[0].map((_, colIndex) =>
      data.map((row) => row[colIndex])
    );

    const ws = XLSX.utils.aoa_to_sheet(transposedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate Excel file and initiate download
    XLSX.writeFile(wb, "Upload Excel.xlsx");
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-3 mb-2">
          <span className="d-flex justify-content-between col-1">
            <i
              className="bi bi-arrow-left-circle "
              onClick={() =>
                history.push(`/meratractor/auctions/modify?uid=${asset_id}`)
              }
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </span>

          <div className="col-10 mt-2 float-start ms-2">
            <span className="d-flex  mb-2">{asset_id}</span>
            <h5 className="">Upload Images Excel</h5>
          </div>
        </div>
      </div>
      <div className="container d-flex d-flex-wrap justify-content-between ">
        <div
          className="col-md-8  col-lg-7 col-12 bg-body p-3 mb-2  rounded"
          style={{
            marginTop: "2rem",
          }}
        >
          {/* <div className="row">
            <div className="d-flex justify-content-between col-2 mt-2">
              <i
                className="bi bi-arrow-left-circle"
                onClick={() => history.goBack()}
                style={{
                  fontSize: "xx-large",
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              ></i>
            </div>
            <div className="col-9">
              <span className="d-flex justify-content-center mb-2">
                {asset_id}
              </span>
              <h5 className="text-center">Upload Images Excel</h5>
            </div>
          </div> */}
          <form className="ms-auto" onSubmit={handleSubmit}>
            <div className="row mt-2">
              <div className="col-md-12 mb-3 form-group">
                <input
                  className="form-control"
                  id="file"
                  type="file"
                  name="file"
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["file"]: e.target.files[0],
                    }))
                  }
                  required
                />
              </div>
            </div>
            <div className="justify-content-center">
              {buttonLoading ? (
                <button
                  className="btn btn-primary mt-2"
                  type="button"
                  disabled
                  id="loadingBtnTp"
                >
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  type="submit"
                  // onClick={(e) => handleSubmit(e, "edit")}
                >
                  Submit
                </button>
              )}
            </div>
            <div className="row mt-2">
              <div className="col-md-1 col-2 mt-2 form-group me-3">
                Download:
              </div>
              <div className="col-md-5 col-7 form-group mytable table-download text-small ms-2">
                <button
                  className="btn btn-link"
                  data-balloon="Download complete results as Pdf"
                  onClick={downloadExcel}
                  data-balloon-pos="up"
                  style={{ cursor: "pointer" }}
                  fdprocessedid="caz3h"
                  id="excel1"
                >
                  sample Excel
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="ms-2">
          <img
            src={uploadAssetImage}
            alt="Fill Form Img"
            width="100%"
            height="80%"
          />
        </div>
      </div>
    </>
  );
};

export default UploadImageExcel;
