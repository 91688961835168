import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import SearchBox from "../../common/SearchBox";
import { useLocation, useHistory, Link } from "react-router-dom";
import auctionService from "../../services/auction/auction.service";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import app from "../../auctionFirebaseConfig";
import { indianCurrencyFormater } from "../../helpers/functions/currencyFormater";
import {
  getDatabase,
  ref,
  onValue,
  onChildChanged,
  onChildAdded,
  off,
} from "firebase/database";
import "firebase/database";
import Swal from "sweetalert2";
import jwtDecode from "jwt-decode";

const AutobidData = () => {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);

  useEffect(() => {
    document.title = "Autobid Data";
  }, []);
  const db = getDatabase(app);
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const auction_id = searchParams.get("uid");
  const [loading, setLoading] = useState(false);
  const [auctionData, setAuctionData] = useState([]);
  const [filterAuctionData, setFilterAuctionData] = useState([]);

  const headers = [
    { header: "Reference No", dataKey: "reference_no" },
    { header: "Reg No", dataKey: "reg_no" },
    { header: "Make Model", dataKey: "make_model" },
    { header: "Manufacture Year", dataKey: "mfg_yr" },
    { header: "Start Price", dataKey: "start_price" },
    { header: "Current Price", dataKey: "current_price" },
    { header: "Recommended Bid", dataKey: "recommended_bid" },
    { header: "No. Of Bids", dataKey: "no_of_bids" },
    { header: "Autobid", dataKey: "amount" },
    { header: "User", dataKey: "user_name" },
    { header: "Phone", dataKey: "user_phone" },
  ];

  useEffect(() => {
    try {
      if (auction_id !== "" && auction_id !== undefined) {
        setLoading(true);
        const form_data = new FormData();
        form_data.append("uid", auction_id);
        auctionService.GetAutoBid(form_data).then((res) => {
          if (res?.status === 200) {
            setAuctionData(res?.data);
            setFilterAuctionData(res?.data);
            const addedData = ref(db, "live_auction/" + auction_id);

            onValue(addedData, (snapshot) => {
              const data = snapshot?.val();
              if (data && (data !== "") & (data !== null)) {
                const updatedAuctionData = res?.data?.map((item) => {
                  const referenceNo = item?.reference_no;
                  const dataKeys = Object?.keys(data);

                  const isReferenceInData = dataKeys?.includes(referenceNo);

                  if (isReferenceInData) {
                    const newData = data[referenceNo];
                    return {
                      ...item,
                      current_price: newData?.latest_bid,
                      ["no_of_bids"]: newData?.no_of_bids,
                    };
                  } else {
                    return item;
                  }
                });

                setLoading(false);
                setAuctionData(updatedAuctionData);
                setFilterAuctionData(updatedAuctionData);
              } else {
                setLoading(false);
              }
            });
          } else {
            errorToast(res);
            setLoading(false);
          }
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (filterAuctionData.length > 0) {
      const changeDataRef = ref(db, "live_auction/" + auction_id);

      const handleChildChanged = onChildChanged(changeDataRef, (snapshot) => {
        const updatedData = snapshot.val();
        setAuctionData((prevData) => ({
          ...prevData,
          [snapshot.key]: updatedData,
        }));
      });

      const handleChildAdded = onChildAdded(changeDataRef, (snapshot) => {
        const newData = snapshot.val();
        setAuctionData((prevData) => ({
          ...prevData,
          [snapshot.key]: newData,
        }));
      });

      return () => {
        // Clean up listeners
        off(changeDataRef, handleChildAdded);
        off(changeDataRef, handleChildChanged);
      };
    }
  }, []);

  const handleDelete = (e, data, index) => {
    e.preventDefault();
    try {
      const form_data = new FormData();
      form_data.append("uid", auction_id);
      form_data.append("phone", data?.user_phone);
      form_data.append("reference_no", data?.reference_no);
      Swal.fire({
        title: "Are you sure?",
        html: `<p>You won't be able to revet this!!</p>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes,delete it!",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            const response = await auctionService.DeleteAutoBid(form_data);
            if (response.status === 200) {
              successToast(response?.msg);
              // window.location.replace();
              window.location.reload();
            } else {
              errorToast(response?.msg);
            }
          } catch (error) {
            console.log(error);
            errorToast(error?.message);
          }
        },
      });
    } catch (error) {
      console.log(error);
      errorToast(error);
    }
  };
  console.log({ filterAuctionData });
  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div
          className="d-flex justify-content-center container"
          style={{ minHeight: "80vh", alignItems: "center" }}
        >
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>
          <div className="container mt-3">
            <div className="row mt-3">
              <div
                className="col-md-5 col-lg-3 col-12"
                style={{ marginTop: "-4px" }}
              >
                <i
                  className="bi bi-arrow-left-circle"
                  onClick={() =>
                    history.push(
                      `/meratractor/auctions/modify?uid=${auction_id}`
                    )
                  }
                  style={{
                    fontSize: "xx-large",
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              <div className="col-md-5 col-lg-3 col-12 ms-auto mt-2">
                <SearchBox
                  placeholder="Search by Reference No..."
                  allData={auctionData}
                  setFilteredData={setFilterAuctionData}
                  return_array={true}
                />
              </div>
            </div>
            <div
              className="table-responsive mt-2 text-center"
              style={{ minHeight: "5vh", maxHeight: "80vh" }}
            >
              <table className=" table table-hover table-bordered mt-2">
                <thead className="thead-dark">
                  <tr
                    className="text-center"
                    style={{ fontSize: "small", position: "sticky", top: 0 }}
                  >
                    <th> Sr. No</th>

                    {headers.map((column) => (
                      <th className="col" key={column.header}>
                        {column.header}
                      </th>
                    ))}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(filterAuctionData) &&
                  Object.entries(filterAuctionData).length === 0 ? (
                    <div className=" text-danger text-center mt-2 "></div>
                  ) : (
                    Object.entries(filterAuctionData).map(
                      (rowData, rowIndex) => (
                        <tr
                          key={rowIndex}
                          style={{
                            fontSize: "small",
                          }}
                        >
                          <td>{rowIndex + 1}</td>
                          {headers.map((header, index) => (
                            <>
                              {header.dataKey === "image_paths" ? (
                                <td>{rowData[1][header?.dataKey]?.length}</td>
                              ) : header.dataKey === "reference_no" ? (
                                <td
                                  className="text-primary"
                                  onClick={() => {
                                    history.push(
                                      `/meratractor/auctions/modify/asset?ref_no=${rowData[1]["reference_no"]}&uid=${auction_id}`
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <b>{rowData[1][header?.dataKey]}</b>
                                </td>
                              ) : header.dataKey === "current_price" ? (
                                <td>
                                  <b>
                                    {indianCurrencyFormater(
                                      rowData[1][header?.dataKey]
                                    )}
                                  </b>
                                </td>
                              ) : header.dataKey === "start_price" ? (
                                <td>
                                  {indianCurrencyFormater(
                                    rowData[1][header?.dataKey]
                                  )}
                                </td>
                              ) : header.dataKey === "recommended_bid" ? (
                                <td>
                                  {indianCurrencyFormater(
                                    rowData[1][header?.dataKey]
                                  )}
                                </td>
                              ) : header.dataKey === "amount" ? (
                                <td>
                                  {indianCurrencyFormater(
                                    rowData[1][header?.dataKey]
                                  )}
                                </td>
                              ) : (
                                <td>{rowData[1][header?.dataKey]}</td>
                              )}
                            </>
                          ))}
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={(e) => {
                                handleDelete(e, rowData[1], rowIndex);
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </table>
              {filterAuctionData && filterAuctionData.length === 0 && (
                <div className="text-danger text-center mt-2 ">
                  No autobid assets added
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AutobidData;
