import React, { useEffect, useState } from "react";
import auctionService from "../../../services/auction/auction.service";
import { errorToast, successToast } from "../../../react-toastfiy/toast";
import ReactLoading from "react-loading";
import SearchBox from "../../../common/SearchBox";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import jwtDecode from "jwt-decode";

const UserNotLinkedWithWallet = () => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [linkWalletLoading, setLinkWalletLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    try {
      setLoading(true);
      setLinkWalletLoading(false);
      auctionService.WalletNotLinked().then((res) => {
        setLoading(false);

        console.log({ res });
        if (Object.entries(res)?.length > 0) {
          setData(res);
          setFilterData(res);
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  }, [linkWalletLoading]);
  const handleLinkToWallet = (e, phone) => {
    e.preventDefault();
    const form_data = new FormData();
    form_data.append("phone", phone);
    Swal.fire({
      //  title: "Are you sure?",
      html: `<p>Are you sure to link ${phone} to Wallet?!</p>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ok!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await auctionService.WalletLinked(form_data);
          console.log({ response });
          if (response?.status === 200) {
            console.log(response.msg);
            Swal.fire({
              html: `<p>${response.msg}!</p>`,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "ok!",
              showLoaderOnConfirm: true,
              preConfirm: async () => {
                try {
                  if (response?.status === 200) {
                    setLinkWalletLoading(true);
                    successToast(response.msg);
                  } else {
                    console.log(response.msg);
                  }
                } catch (error) {
                  console.log(error);
                  errorToast(error?.message);
                }
              },
            });
          } else {
            console.log(response.msg);
          }
        } catch (error) {
          console.log(error);
          errorToast(error?.message);
        }
      },
    });
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div
          className="container table-responsive mt-2 d-flex justify-content-center text-align-center"
          style={{ minHeight: "5vh", maxHeight: "80vh" }}
        >
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>
          <div className="container responsive mt-2">
            <div className="row">
              <div className="d-flex justify-content-between col-12 col-md-4 col-lg-3">
                <i
                  className="bi bi-arrow-left-circle"
                  onClick={() => history.push("/meratractor/wallet-home")}
                  style={{
                    fontSize: "xx-large",
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              <div className="col-12 col-md-4 col-lg-3 ms-auto mt-2 mb-1">
                <SearchBox allData={data} setFilteredData={setFilterData} />
              </div>
            </div>
          </div>
          <div
            className="container table-responsive mt-2 text-center"
            style={{ minHeight: "5vh" }}
          >
            <table className="table table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col">Sr No.</th>
                  <th className="text-align-start ms-2">Name</th>
                  <th className="text-align-start">Mobile Number</th>
                  <th scope="col">State</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                Object.entries(filterData) &&
                Object.entries(filterData).length === 0 ? (
                  <div className="text-danger mt-2 "></div>
                ) : (
                  Object.entries(filterData).map(([key, rowData], rowIndex) => (
                    <tr style={{ fontSize: "small" }}>
                      <td>{rowData?.sr_no}</td>
                      <td className="text-align-start ms-2">{rowData?.name}</td>
                      <td className="text-align-start">{rowData?.phone}</td>
                      <td>{rowData?.state}</td>
                      <td>
                        <Link>
                          <div
                            onClick={(e) => {
                              handleLinkToWallet(e, rowData?.phone);
                            }}
                          >
                            Link to Wallet
                          </div>
                        </Link>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            {data && data?.length === 0 && (
              <div className="text-danger text-center mt-2 ">No Data</div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default UserNotLinkedWithWallet;
