import React, { useEffect, useState, useCallback } from "react";
import auctionService from "../../services/auction/auction.service";
import ReactLoading from "react-loading";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import { Link, useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";

const MaratractorPermissions = () => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  const history = useHistory();
  const [userData, setUserData] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [debounceVal, setDebounceVal] = useState("");
  const [updatedData, setUpdatedData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [permissionLoading, setPermissionLoading] = useState(false);
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const [arrayLength, setArrayLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionFlag, setSuggestionFlag] = useState(false);
  const handleAccordionToggle = (index) => {
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };

  useEffect(() => {
    if (debounceVal === "" && currentPage !== 0) {
      setUserLoading(true);
      const form_data = new FormData();
      form_data.append("pg_no", currentPage);
      auctionService.GetUserListData(form_data).then((res) => {
        setUserLoading(false);
        let users = res.users;
        if (res.users?.length > 0) {
          let user_data_list = Object.entries(res?.users).map(
            ([key, val], index) => [val[1], val[0]]
          );
          setUserData(user_data_list);
          let user_list = users.map((val) => val[1]);
          const lenTrans = users[0];
          const userLength = lenTrans.map((val) => val);
          const lenValue = userLength[2].len;
          setArrayLength(lenValue);
          setPermissionLoading(true);
          const form_data = new FormData();
          form_data.append("users", JSON.stringify(user_list));
          auctionService.Permissions(form_data).then((res) => {
            setPermissionLoading(false);
            if (res?.length > 0) {
              setUpdatedData(res);
            }
          });
        }
      });
    }
  }, [currentPage, debounceVal]);

  useEffect(() => {
    auctionService.GetStateListData().then((res) => {
      let state_list = res.states;
      if (res?.states?.length > 0) {
        setStateData(state_list);
      }
    });
  }, []);

  const handleChange = (e, state, val) => {
    e.preventDefault();

    const index = updatedData.findIndex(
      (item) => item.user_id === val[0] && item.state_id === state
    );

    if (index !== -1 && updatedData[index].permission === true) {
      setUpdatedData((prevData) => [
        ...prevData.slice(0, index),
        { ...prevData[index], permission: false },
        ...prevData.slice(index + 1),
      ]);
    } else if (index !== -1 && updatedData[index].permission === false) {
      setUpdatedData((prevData) => [
        ...prevData.slice(0, index),
        { ...prevData[index], permission: true },
        ...prevData.slice(index + 1),
      ]);
    }
    setPermissionLoading(true);
    const form_data = new FormData();
    form_data.append("user", val[0]);
    form_data.append("state", state);
    auctionService.UpdatePermissions(form_data).then((res) => {
      setPermissionLoading(false);
      if (res?.status === 200) {
        successToast(res.msg);
      } else {
        errorToast(res.msg);
      }
    });
  };

  const pageCount = Math.ceil(arrayLength / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, arrayLength);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    try {
      if (debounceVal !== "") {
        const form_data = new FormData();
        form_data.append("search", debounceVal);
        setUserLoading(true);
        auctionService.SearchPermission(form_data).then((res) => {
          const user = res;
          setUserLoading(false);
          if (Object.entries(user)?.length > 0) {
            let user_list = Object.entries(user).map(([key, val], index) => [
              val?.phone,
              val?.name,
            ]);
            let user_permission = Object.entries(user).map(
              ([key, val], index) => val?.phone
            );
            let user_list_sugg = Object.entries(user).map(
              ([key, val], index) => val
            );
            setUserData(user_list);
            setSuggestions(user_list_sugg);
            setArrayLength(0);
            setPermissionLoading(true);
            const form_data = new FormData();
            form_data.append("users", JSON.stringify(user_permission));
            auctionService.Permissions(form_data).then((res) => {
              setPermissionLoading(false);
              if (res?.length > 0) {
                console.log({ res });
                setUpdatedData(res);
                setCurrentPage(0);
              }
            });
          } else {
            setUserData([]);
            errorToast(res?.msg);
          }
        });
      } else {
        setCurrentPage(1);
        setUserLoading(true);
        const form_data = new FormData();
        form_data.append("pg_no", currentPage);
        auctionService.GetUserListData(form_data).then((res) => {
          setUserLoading(false);
          let users = res.users;
          if (users?.length > 0) {
            let user_list = users.map((val) => val[1]);
            setUserData(users);
            const lenTrans = users[0];
            const userLength = lenTrans.map((val) => val);
            const lenValue = userLength[2].len;
            setArrayLength(lenValue);
            setPermissionLoading(true);
            const form_data = new FormData();
            form_data.append("users", JSON.stringify(user_list));
            auctionService.Permissions(form_data).then((res) => {
              setPermissionLoading(false);
              if (res?.length > 0) {
                console.log({ res });
                setUpdatedData(res);
              }
            });
          }
        });
      }
    } catch (error) {
      errorToast(error.msg);
    }
  }, [debounceVal]);

  function debounce(cb, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const debouncedChange = useCallback(
    debounce((inputValue) => {
      setDebounceVal(inputValue);
    }, 900),
    []
  );

  const handleChangeSearch = (e) => {
    setSuggestionFlag(true);
    const inputValue = e.target.value;
    setSearchVal(inputValue);
    debouncedChange(inputValue);
  };

  const handleSuggestionSelect = (suggestion) => {
    setSuggestionFlag(false);
    setSearchVal(suggestion.name);
    debouncedChange(suggestion.phone);
    setSuggestions(() => [suggestion]);
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-3 mb-2">
          <span className="d-flex justify-content-between col-2 col-sm-1">
            <i
              className="bi bi-arrow-left-circle "
              onClick={() => history.push("/meratractor")}
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </span>

          <div
            className="col-10 col-md-2 mt-2 text-start"
            style={{ marginLeft: "-10px" }}
          >
            <h4 className="">Permission</h4>
          </div>
          <div className="col-12 col-md-9 mt-2" style={{ marginLeft: "auto" }}>
            <input
              className="form-control"
              type="text"
              name="name"
              placeholder="Search by Name or Phone"
              value={searchVal}
              onChange={handleChangeSearch}
            />
          </div>
        </div>
      </div>
      {userLoading ? (
        <div
          className="d-flex justify-content-center container"
          style={{ minHeight: "80vh", alignItems: "center" }}
        >
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>
          <div className="container d-flex d-flex-wrap justify-content-between">
            <div className="accordion" id="accordionExample">
              {!userLoading &&
                userData.length > 0 &&
                userData.map((val, index) => (
                  <div className="accordion-item" key={index}>
                    <h4 className="accordion-header" id={`heading${index}`}>
                      <button
                        className={`accordion-button ${
                          openAccordionIndex === index ? "" : "collapsed"
                        }`}
                        type="button"
                        onClick={() => handleAccordionToggle(index)}
                        aria-expanded={
                          openAccordionIndex === index ? "true" : "false"
                        }
                        aria-controls={`collapse${index}`}
                      >
                        {`${val[0]} ${val[1]}`}
                      </button>
                    </h4>
                    <div
                      id={`collapse${index}`}
                      className={`accordion-collapse collapse ${
                        openAccordionIndex === index ? "show" : ""
                      }`}
                      aria-labelledby={`heading${index}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="container">
                        <div className="row">
                          {stateData.map((stateVal, indexState) => {
                            return (
                              <div
                                key={indexState}
                                className="col-md-4 col-sm-6 mb-3"
                              >
                                <input
                                  type="checkbox"
                                  className="form-box ms-2"
                                  disabled={permissionLoading === true}
                                  checked={Boolean(
                                    updatedData.find(
                                      (checkVal) =>
                                        checkVal.user_id === val[0] &&
                                        checkVal.state_id === stateVal &&
                                        checkVal.permission === true
                                    )
                                  )}
                                  onChange={(e) =>
                                    handleChange(e, stateVal, val)
                                  }
                                />
                                <span className="ms-3">{stateVal}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              {!userLoading && userData.length > 0 && arrayLength > 10 && (
                <div className="d-flex justify-content-center mt-3">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      {currentPage > 0 && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            to="#"
                            onClick={() => paginate(1)}
                            style={{ cursor: "pointer" }}
                          >
                            First
                          </a>
                        </li>
                      )}
                      {currentPage > 0 && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            to="#"
                            onClick={() =>
                              paginate(currentPage > 1 ? currentPage - 1 : 1)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {"<"}
                          </a>
                        </li>
                      )}

                      {Array.from(
                        { length: Math.min(pageCount, 5) },
                        (_, i) => {
                          const pageNumber =
                            currentPage - Math.floor(7 / 2) + i;
                          return (
                            pageNumber > 0 &&
                            pageNumber <= pageCount && (
                              <li
                                key={pageNumber}
                                className={`page-item ${
                                  currentPage === pageNumber ? "active" : ""
                                }`}
                              >
                                <a
                                  className="page-link"
                                  to="#"
                                  onClick={() => handlePageChange(pageNumber)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {pageNumber}
                                </a>
                              </li>
                            )
                          );
                        }
                      )}

                      {currentPage < pageCount && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            to="#"
                            onClick={() => paginate(currentPage + 1)}
                            style={{ cursor: "pointer" }}
                          >
                            {">"}
                          </a>
                        </li>
                      )}

                      {currentPage < pageCount && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            to="#"
                            onClick={() => paginate(pageCount)}
                            style={{ cursor: "pointer" }}
                          >
                            Last
                          </a>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MaratractorPermissions;
