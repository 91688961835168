import React, { useEffect, useState } from "react";

import jwtDecode from "jwt-decode";
import { useHistory, useLocation, Link } from "react-router-dom";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";
import uploadAssetImage from "../../img/undraw_upload_image_re_svxx.svg";
import Swal from "sweetalert2";

const AddAssetImage = () => {
  useEffect(() => {
    document.title = "Add Asset Image";
  }, []);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 467);
  console.log({ isSmallScreen });

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 467);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const token = localStorage.getItem("refreshToken");
  const history = useHistory();
  const user = jwtDecode(token);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const asset_id = searchParams.get("ref_no");
  const uid = searchParams.get("uid");
  const url = `${asset_id}&uid=${uid}`;

  const [data, setData] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = (e, type) => {
    e.preventDefault();
    try {
      setButtonLoading(true);

      const form_data = new FormData();
      data?.asset_images?.forEach((file, index) => {
        form_data.append("asset_images", file);
      });
      form_data.append("ref_id", asset_id);
      form_data.append("uid", uid);

      auctionService.UploadAssetPictures(uid, form_data).then((res) => {
        setButtonLoading(false);
        console.log({ res });
        if (res?.status === 200) {
          Swal.fire({
            title: "Image Upload Successfully!",
            // text: "You clicked the button!",
            icon: "success",
          }).then(() => {
             history.push(
               `/meratractor/auctions/modify/view/asset_images?ref_no=${asset_id}&uid=${uid}`
             );
          });
          successToast(res?.msg);
         
        } else {
          errorToast(res?.msg);
        }
      });
    } catch (error) {
      errorToast(error);
      console.log(error);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setData((prevData) => ({
      ...prevData,
      ["asset_images"]: files,
    }));
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-3 mb-2">
          <span className="d-flex justify-content-between col-1">
            <i
              className="bi bi-arrow-left-circle"
              onClick={() =>
                history.push(
                  `/meratractor/auctions/modify/asset?ref_no=${asset_id}&uid=${uid}`
                )
              }
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </span>
          <div className="col-10 mt-2 float-start ms-2">
            <h4 className="d-flex  mb-2">{asset_id}</h4>
            <h5>Upload Asset Images</h5>
          </div>
        </div>
      </div>
      <div className="container d-flex d-flex-wrap justify-content-between ">
        <div
          className="col-md-7  col-12   bg-body p-3 mb-2  rounded"
          // style={{
          //   marginTop: "2rem",
          // }}
        >
          {/* <hr className="soften mt-2" /> */}
          <form className=" ms-auto" onSubmit={handleSubmit}>
            <div className="row mt-3">
              <div className="col-12 mb-3 form-group">
                <input
                  className="form-control"
                  placeholder="Reference No."
                  id="reference_no"
                  type="file"
                  multiple
                  onChange={(e) => handleFileChange(e)}
                  // onChange={(e) => setData(e?.target?.files)}
                  required
                />
              </div>
              <div className="d-flex  justify-content-center mt-4 form-group">
                {buttonLoading ? (
                  <button
                    className="btn btn-primary col-4"
                    type="button"
                    disabled
                    id="loadingBtnTp"
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </button>
                ) : (
                  <button
                    className="btn btn-primary col-4"
                    type="submit"
                    // onClick={(e) => handleSubmit(e, "edit")}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="ms-2">
          <img
            src={uploadAssetImage}
            alt="Fill Form Img"
            width="100%"
            height="80%"
          />
        </div>
      </div>
    </>
  );
};

export default AddAssetImage;
