import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../css/dashboard.css";
// import dashboardService from '../../services/dashboard.service';
import jwt from "jwt-decode";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ErrorHandler from "../../common/ErrorHandler";
import useApprovedPendingCount from "../../hooks/useApprovedPendingCount";
import usePaymentPendingCount from "../../hooks/usePaymentPendingCount";
import { errorToast } from "../../react-toastfiy/toast";
import dashboardService from "../../services/dashboard.service";
import formService from "../../services/form.service";

import AddTaskModal from "../dashboard/AddTaskModal";
import ARDashboardReportTabbar from "../dashboard/arDashboardReportTabbar";
import ARDashboardTabbar from "../dashboard/arDashboardTabbar";
import QuickView from "../dashboard/quickView";
import TableModal from "../dashboard/TableModal";
import UpdateMilestoneDash from "../dashboard/updateMilestoneDash";

function ARDashboard() {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwt(currentUser);
  const a = 0;
  let location = useLocation();
  let history = useHistory();

  let operationName = "";

  const [columnNames, setColumnNames] = useState([]);
  const [addTaskShow, setAddTaskShow] = useState(false);
  const [addmodalShow, setAddModalShow] = useState(false);
  const [TableModalShow, setTableModalShow] = useState(false);
  const [TableTitle, setTableTitle] = useState("");
  const [TableData, setTableData] = useState({});
  const [myTask, setMyTask] = useState([]);
  const [releasePending, setReleasePending] = useState([]);
  const [bankNocTTO, setBankNocTTO] = useState([]);
  const [name, setName] = useState("");
  const [desig, setDesig] = useState("");
  const [mainObj, setMainObj] = useState({});
  const [active, setactive] = useState("dashboard");

  const ApprovedPendingCount = useApprovedPendingCount(active);
  const PaymentPendingCount = usePaymentPendingCount(active);
  const [operationId, setOperationId] = useState("");
  const [activityId, setActivityId] = useState("");
  const [taskId, setTaskId] = useState("");
  const [milestoneId, setMilestoneId] = useState("");
  const [dataToBeCollected, setDataToBeCollected] = useState("");

  const [topRightModal, setTopRightModal] = useState(false);
  const [quickViewData, setQuickViewData] = useState({});

  let { id } = useParams();
  const newId = id?.split(":")[1];
  const formdata = new FormData();
  formdata.append("id", id);

  const [phone, setPhone] = useState(id.split(":")[1].split("&")[0]);
  const [stateName, setStateName] = useState(
    id.split(":")[1].split("&")[1].split("=")[1]
  );

  let formData = new FormData();
  formData.append("id", "id");
  let tempData = {};
  const [obj, setObj] = useState([]);
  useEffect(() => {
    try {
      document.title = "Dashboard";
      let userDetails = new FormData();
      userDetails.append("phone", phone);
      dashboardService.getUserDetailsByPhone(userDetails).then((res) => {
        setName(res.data.name + "(" + res.data.designation + ")");
        setDesig(res.data.designation);
        let desig = res.data.designation;

        if (desig.includes("AR")) {
          const myTask = {};
          myTask["phone"] = phone;
          myTask["state"] = stateName;

          // dashboardService.getAuctionPersonnelRelease(myTask).then((res) => {
          //   if (res.data.length == 0) {
          //     res = ["blank"];
          //     setReleasePending(res);
          //   } else {
          //     setReleasePending(res.data);
          //   }
          // });

          // dashboardService.getBankNocAndTTODashboard(myTask).then((res) => {
          //   if (res.data.length == 0) {
          //     res = ["blank"];
          //     setBankNocTTO(res);
          //   } else {
          //     setBankNocTTO(res.data);
          //   }
          // });
        }
      });
    } catch (error) {
      console.error(error);
      errorToast(error?.message);
    }
    // setDashboardData(data);
  }, []);

  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    return setSelected(i);
  };

  const handlechangetab = (e) => {
    const id = e.target.getAttribute("id");
    setactive(id);
    localStorage.setItem("active_tab", id);
  };

  useEffect(() => {
    const lastActiveTab = localStorage.getItem("active_tab");
    if (lastActiveTab) {
      setactive(lastActiveTab);
    }
  }, []);

  const handleMarkRelease = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("task_id", e.currentTarget.id);
    formData.append("data", JSON.stringify({ release_document: "yes" }));

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // make axios post request
          const response = await formService.EditTaskData(formData);
          if (response.status === 200) {
            Swal.fire(
              "Deleted!",
              "Your data has been deleted.",
              "success"
            ).then((result) => {
              result && window.location.replace("/user/states");
            });
          }
          response.status !== 200 && errorToast(response.msg);
        } catch (error) {
          console.log(error);
          errorToast(error?.message);
        }
      }
    });
  };

  console.log({ newId });

  try {
    return (
      <>
        {addTaskShow && (
          <AddTaskModal
            fullscreen={false}
            show={addTaskShow}
            // activity_id={activity.activity_id}
            // activity={activity}
            // onExit={handleSaveMilestone}
            // onExited={SetActivityCurrent_ids}
            onHide={() => {
              setAddTaskShow(false);
              // window.location.replace("/user/states");
            }}
          />
        )}
        {TableModalShow && (
          <TableModal
            fullscreen={false}
            show={TableModalShow}
            mytask={myTask}
            tabletitle={TableTitle}
            tabledata={TableData}
            colnames={columnNames}
            onHide={() => {
              setTableModalShow(false);
              // window.location.replace("/user/states");
            }}
          />
        )}

        {releasePending != ""
          ? addmodalShow && (
              <UpdateMilestoneDash
                fullscreen={false}
                show={addmodalShow}
                milestone_data={dataToBeCollected}
                operation_id={operationId}
                activity_id={activityId}
                milestone_id={milestoneId}
                task_id={taskId}
                phone={phone}
                completed={false}
                otherData={false}
                dashboardFlag={true}
                stateName={stateName}
                onHide={() => {
                  setAddModalShow(false);
                  // window.location.replace("/user/states");
                }}
              />
            )
          : addmodalShow && (
              <UpdateMilestoneDash
                fullscreen={false}
                show={addmodalShow}
                milestone_data={null}
                onHide={() => {
                  setAddModalShow(false);
                  // window.location.replace("/user/states");
                }}
              />
            )}

        {topRightModal && (
          <QuickView
            show={topRightModal}
            data={quickViewData}
            onHide={() => {
              setTopRightModal(false);
              // window.location.replace("/user/states");
            }}
          />
        )}

        {desig === "" ? (
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
            className="reactLoadingDash"
          />
        ) : (
          <>
            <div className="container-fluid shadow-sm">
              <div className="container">
                <div className="row">
                  <div className="d-sm-block d-md-none d-lg-none d-xl-none">
                    <div className="row">
                      <div className="col-12">
                        <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                      </div>
                      <div className="col-12">
                        <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                      </div>
                    </div>
                  </div>

                  <div className="d-none d-md-block d-lg-none d-xl-none">
                    <div className="row">
                      <div className="col-12 col-md-6 mt-4 ">
                        <h2 className="m4">{user.name}</h2>
                      </div>
                      <div className="col-12 col-md-6 mt-4 mb-4">
                        <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                      </div>
                    </div>
                  </div>

                  <div className="d-none d-md-none d-lg-block d-xl-block">
                    <div className="row">
                      <div className="col-12 col-md-8 mt-4 ">
                        <h2 className="m4">{user.name}</h2>
                      </div>
                      <div className="col-12 col-md-4 mt-4 mb-4">
                        <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-3">
              <div className="d-flex justify-content-between">
                <i
                  className="bi bi-arrow-left-circle"
                  onClick={() =>
                    history.push(
                      `/auction_representative:${user?.phone}&state=${user?.state}`
                    )
                  }
                  style={{
                    fontSize: "xx-large",
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <button
                    className={
                      active === "dashboard"
                        ? `nav-link active btn btn-link`
                        : `nav-link btn btn-link`
                    }
                    aria-current="page"
                    id="dashboard"
                    onClick={handlechangetab}
                  >
                    Dashboard
                  </button>
                </li>

                <li className="nav-item">
                  <button
                    className={
                      active === "report"
                        ? `nav-link active btn btn-link`
                        : `nav-link btn btn-link`
                    }
                    id="report"
                    onClick={handlechangetab}
                  >
                    Report
                  </button>
                </li>
                {/* <li className="nav-item">
                  <button
                    className={
                      active === "add-users"
                        ? `nav-link active btn btn-link`
                        : `nav-link btn btn-link`
                    }
                    id="add-users"
                    onClick={handlechangetab}
                  >
                    Users
                  </button>
                </li> */}
              </ul>
            </div>

            {active === "dashboard" && (
              <>
                <ARDashboardTabbar />
              </>
            )}

            {active === "report" && (
              <>
                <ARDashboardReportTabbar />
              </>
            )}
            {/* {active === "add-users" && (
              <>
                <UnifiedUsersList />
              </>
            )} */}
          </>
        )}
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
  // }
}

export default ARDashboard;
